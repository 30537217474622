import * as React from 'react';
import Button from '@mui/material/Button';
import {Box, Typography} from "@mui/material";
import {lightGreen} from '../../colors/colors';

const OfferteForm = (props: { onClickOpen: () => void }) => {
    const handleClickOpen = () => {
        props.onClickOpen()
    };

    return (
        <Box>
            <Button sx={{display: {xs: 'none', md: 'flex'}, backgroundColor: lightGreen, borderRadius: '16px', width: '150px', mr: 20}} onClick={handleClickOpen} style={{textTransform: 'none'}}>
                <Typography align="justify" color="black" sx={{fontSize: 18}}>
                    Gratis advies
                </Typography>
            </Button>
            <Button sx={{display: {xs: 'flex', md: 'none'}, backgroundColor: lightGreen, borderRadius: '16px', width: '100px'}} onClick={handleClickOpen} style={{textTransform: 'none'}}>
                <Typography align="justify" color="black" sx={{fontSize: 12}}>
                    Gratis advies
                </Typography>
            </Button>
        </Box>
    );
}

export default OfferteForm;
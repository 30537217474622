import React from 'react';
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";


const TextBox = (props: { children: string | number | boolean | React.ReactElement<any, string | React.JSXElementConstructor<any>> | Iterable<React.ReactNode> | React.ReactPortal | null | undefined; }) => {
    return(
        <Box>
            <Box m="auto" sx={{ display: { xs: 'none', md: 'flex', color:'black', width: '65%'}, mb: 2, mt: 2}}>
                <Typography variant="body1" align="justify" color="black">
                    {props.children}
                </Typography>
            </Box>
            <Box m="auto" sx={{ display: { xs: 'flex', md: 'none', color:'black', width: '90%'}, mb: 2, mt: 2}}>
                <Typography variant="body1" align="justify" color="black">
                    {props.children}
                </Typography>
            </Box>
        </Box>
    );
}

export default TextBox;
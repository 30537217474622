import {Box, Divider} from "@mui/material";
import zonnepanelenHeader from "../images/zon3v2.webp";
import {
    answersZonnepanelen,
    prod1,
    prod2,
    textBox1,
    textBox2,
    textBox3,
    textBox4,
    textBox5,
    textBox6
} from "../textblocks/ZonnepanelenText";
import {darkGreen, lightGreen} from "../colors/colors";
import Grid from "@mui/material/Grid";
import Product from "../component/Product";
import zonnepaneel1 from "../images/zonnepaneel1.png";
import zonnepaneel2 from "../images/zonnepaneel2.png";
import React from "react";
import TextBox from "../text/TextBox";
import VoorbeeldMerken from "../component/VoorbeeldMerken";
import HeaderZonnepanelenMd from "../component/headers/HeaderZonnepanelenMd";
import Question from "../text/Question";
import HeaderXs from "../component/headers/HeaderXs";

const answers = answersZonnepanelen;
const title = <>Waarom investeren <br/> in zonnepanelen?</>;


const Zonnepanelen = () => {

    return (
        <Box>
            <HeaderZonnepanelenMd answers={answers}/>
            <HeaderXs answers={answers} title={title} image={zonnepanelenHeader}/>
            <Box sx={{backgroundColor: darkGreen, pt: 4, pb: 4}}>
                <VoorbeeldMerken>
                    Voorbeeld zonnepanelen:
                </VoorbeeldMerken>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="top"
                        justifyContent="center"
                    >
                        <Product
                            image={zonnepaneel1}
                            title={'Black type-n paneel'}
                            info={prod1}/>
                        <Product
                            image={zonnepaneel2}
                            title={'N-type bifacial'}
                            info={prod2}/>
                    </Grid>
                </Grid>
            </Box>
            <Question>Gratis advies en energieanalyse</Question>
            <Box sx={{display: {xs: 'none', md: 'block'}, width: '65%', pl: '17.5%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <Box sx={{display: {xs: 'block', md: 'none'}, width: '100%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <TextBox>
                {textBox1}
            </TextBox>

            <Question>Welke factoren hebben invloed op de terugverdientijd?</Question>
            <Box sx={{display: {xs: 'none', md: 'block'}, width: '65%', pl: '17.5%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <Box sx={{display: {xs: 'block', md: 'none'}, width: '100%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <TextBox>{textBox2}
            </TextBox>
            <TextBox>
                {textBox3}
            </TextBox>
            <TextBox>
                {textBox4}
            </TextBox>
            <TextBox>
                {textBox5}
            </TextBox>
            <TextBox>
                {textBox6}
            </TextBox>
        </Box>
    );
}

export default Zonnepanelen;
import {Box, Divider} from "@mui/material";
import {lightGreen, white} from "../colors/colors";
import Typography from "@mui/material/Typography";
import React from "react";

const ExpandedAnswer = (props: {title: string, children: JSX.Element}) => {
    return (
        <Box sx={{pt: 8, pb: 8}}>
            <Typography
                variant="h5"
                sx={{
                    display: {md: 'flex', color: white},
                    fontFamily: 'monospace',
                    fontWeight: 600,
                    ml: 1
                }}>
                {props.title}
            </Typography>
            <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            <Typography
                sx={{
                    display: {xs: 'none', md: 'flex', color: white},
                    ml: 1
                }}
                align="justify"
            >
                {props.children}
            </Typography>
        </Box>

    );
}

export default ExpandedAnswer;
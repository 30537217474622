import Typography from "@mui/material/Typography";
import React from "react";
import {Box} from "@mui/material";


const SubTitle = (props: { children: string }) => {
    return (
        <Box m="auto" justifyContent="center">
            <Typography
                variant="h5"
                m="auto"
                sx={{
                    display: {xs: 'none', md: 'flex', color: 'black', width: '65%'},
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'black',
                    mt: 3,
                }}
            >

                {props.children}
            </Typography>
            <Typography
                variant="h5"
                m="auto"
                sx={{
                    display: { xs: 'flex', md: 'none', color:'black', width: '90%'},
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'black',
                    mt: 3,
                }}
            >

                {props.children}
            </Typography>
        </Box>
    );
}

export default SubTitle;
import { Box } from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";


const TitleWhite = (props: { children: string }) => {
    return (
        <Box>
            <Typography
                variant="h3"
                m="auto"
                sx={{
                    display: {xs: 'none', md: 'flex', color: 'white', width: '65%'},
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'black',
                    mt: 2,
                    textDecoration: 'none',
                }}
            >
                {props.children}
            </Typography>
            <Typography
                variant="h5"
                m="auto"
                sx={{
                    display: { xs: 'flex', md: 'none', color:'white', width: '90%'},
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.3rem',
                    color: 'black',
                    textDecoration: 'none',
                }}
            >
                {props.children}
            </Typography>
        </Box>
    );
}

export default TitleWhite;
import Typography from "@mui/material/Typography";
import React from "react";


const VoorbeeldMerken = (props: {children: string}) => {
    return (
        <Typography variant="h5" m="auto"
                    sx={{color: 'white', width: '65%'}}>
            {props.children}
        </Typography>
    );
}

export default VoorbeeldMerken;
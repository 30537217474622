import home from "../../images/home1.webp";
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import cert from "../../images/rescertnlv5.png";
import Button from "@mui/material/Button";
import {lightGreen} from "../../colors/colors";
import products from "../../images/home2.png";
import mainLogo from "../../images/logo5v2.png";
import React from "react";

const HeaderHomeXs = (props: {onClickOpen: () => void}) => {

    const onClickOpen = () => {
        props.onClickOpen()
    }

    return (
        <Box
            m="auto"
            justifyContent="center"
            sx={{
                display: {xs: 'block', md: 'none'},
                width: '100%', backgroundImage: `url(${home})`,
                backgroundRepeat: "no-repeat", pb: 20, backgroundAttachment: 'fixed', backgroundSize: 'cover'
            }}>
            <Box sx={{width: '100%', height: 200, position: 'relative'}}>
                <Grid
                    container
                    direction="row"
                    xs={6}
                    spacing={2}
                    sx={{width: '75%', position: 'absolute', left: '10%', top: '60%'}}>
                    <Grid
                        item
                        xs={12}>
                        <Typography sx={{fontSize: 24, lineHeight: 1}}>
                            Uw partner voor <br/> betrouwbare <br/> energie
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <img src={cert} alt="logo" style={{width: '100%'}}/>
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <Button sx={{backgroundColor: lightGreen, width: '90%', borderRadius: '16px'}} style={{textTransform: 'none'}} onClick={onClickOpen}>
                            <Typography align="justify" color="black">
                                Gratis advies
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{left: '60%', top: '55%', position: 'absolute'}}>
                    <img src={products} alt="logo" style={{width: '150px'}}/>
                </Box>
                <Box sx={{top: 35, position: 'absolute',}}>
                    <img src={mainLogo} alt="logo" style={{width: '90%', marginLeft: '5%'}}/>
                </Box>
            </Box>
        </Box>
    );
}

export default HeaderHomeXs;
import Grid from "@mui/material/Grid";
import {bulletArrow} from "../icons/icons";
import Typography from "@mui/material/Typography";
import React from "react";


const ArrowBullet = (props: { children: JSX.Element, title: string }) => {
    return (
        <React.Fragment>
            <Grid
                container
                spacing={0}
                sx={{my: 2,display: {xs: 'none', md: 'flex'}}}
            >
                <Grid
                    item
                    xs={2}
                    sx={{pr: -5}}
                >
                    {bulletArrow}
                </Grid>
                <Grid
                    item
                    xs={8}
                >
                    <Typography variant="h5">
                        {props.title}
                    </Typography>
                    <Typography variant="body1">
                        {props.children}
                    </Typography>
                </Grid>
            </Grid>
            <Grid
                container
                spacing={0}
                sx={{my: 2,display: {xs: 'flex', md: 'none'}}}
            >
                <Grid
                    item
                    xs={2}
                    sx={{pl: 0}}
                >
                    {bulletArrow}
                </Grid>
                <Grid
                    item
                    xs={8}
                    sx={{pl: 3}}
                >
                    <Typography variant="subtitle1">
                        {props.title}
                    </Typography>
                    <Typography variant="body2" align="justify" sx={{fontSize: 12}}>
                        {props.children}
                    </Typography>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}

export default ArrowBullet;
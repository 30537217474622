import brent from "../../images/brentMobile.webp";
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import {white} from "../../colors/colors";

const HeaderOverOnsXs = (props: { blocks: { title: string, explanation: string, icon: JSX.Element }[] }) => {
    return (
        <Box
            m="auto"
            justifyContent="center"
            sx={{
                display: {xs: 'flex', md: 'none'},
                width: '100%',
                backgroundImage: `url(${brent})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
            }}>
            <Box sx={{width: '100%', minHeight: 500, position: 'relative'}}>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    sx={{width: '60%', position: 'absolute', left: '10%', top: '5%'}}>
                    <Grid
                        item
                        xs={12}>
                        <Typography sx={{fontSize: 35, lineHeight: 1, color: 'white'}}>
                            Wie zijn wij?
                        </Typography>
                    </Grid>
                </Grid>
                <Box m="auto" justifyContent="center"
                     sx={{display: {xs: 'flex', md: 'none'}, width: '100%', mt: '300px'}}>
                    <Grid container
                          sx={{display: {xs: 'flex', md: 'none'}, width: '100%'}}>
                        {props.blocks.map((block) => (
                            <Grid
                                container
                                spacing={0}
                                direction="column"
                                alignItems="center"
                                justifyContent="center"
                                sx={{backgroundColor: white, borderRadius: '16px',mt: 4, mb: 2, pb:2,ml: '1%', mr: '1%', opacity: '90%'}}
                            >
                                <Box m="auto">
                                    {block.icon}
                                </Box>
                                <Box m="auto" sx={{width: '90%'}}>
                                    <Typography gutterBottom align="center" variant="subtitle1" component="div">
                                        {block.title}
                                    </Typography>
                                    <Typography variant="body2" align="justify" color="text.secondary">
                                        {block.explanation}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </Box>
    );
}

export default  HeaderOverOnsXs;
import * as React from 'react';
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import {useNavigate} from 'react-router-dom';


const PageMenuItem = (props: {page: string, handleCloseNavMenu: (page: string) => void}) => {
    const navigate = useNavigate();
    const handleCloseNavMenu = () => {
        props.handleCloseNavMenu(props.page)
        navigate('/' + props.page.replace(/ /g,'').toLowerCase());
    }

    return(
        <MenuItem key={props.page} onClick={handleCloseNavMenu}>
            <Typography textAlign="center">{props.page}</Typography>
        </MenuItem>
    );
}

export default PageMenuItem;
import {lightGreen, white} from "../../colors/colors";
import Grid from "@mui/material/Grid";
import {Box, Divider} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";


const ModalCardWithoutIconMobile = (props: { title: string, children: string}) => {
    return (
        <Box
            sx={{width: '90%', backgroundColor: white, borderRadius: '16px', mt: 6, mb: 6, ml: '5%', mr: '5%'}}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Box m="auto" sx={{width: '60%'}}>
                    <Typography gutterBottom align="center" variant="h5" component="div">
                        {props.title}
                    </Typography>
                    <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen, mb: 2}}/>
                    <Typography variant="body1" align="justify" color="text.secondary">
                        {props.children}
                    </Typography>
                </Box>
            </Grid>
        </Box>
    );
}

export default ModalCardWithoutIconMobile;
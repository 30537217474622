import {ClickableAnswer} from "../types";
import Link from "@mui/material/Link";
import {capacity, checkboxAnswer, energy, safety} from "../icons/icons";
import ExpandedAnswer from "../component/ExpandedAnswer";
import Typography from "@mui/material/Typography";
import {lightGreen} from "../colors/colors";
import React from "react";

export const why: string = 'Waarom investeren in batterijen?';
export const opslagTitle: string = 'Energieopslag';
export const opslag: JSX.Element = <Typography>Wilt u het maximale uit uw zonnepanelen halen, dan is een
thuisbatterij van groot belang. Een thuisbatterij slaat namelijk de energie op
die uw zonnepanelen overdag produceren. Zo kunt u 's avonds genieten van de energie die uw zonnepanelen overdag hebben opgewekt.</Typography>

export const opslagShort: string = 'Haal zoveel mogelijk uit uw zonnepanelen door uw zelfopgewekte energie op te slaan.';
export const capaciteitTitle: string = 'Capaciteitstarief';
export const capaciteit: JSX.Element = <Typography>Vanaf 2023 heft de overheid een capaciteitstarief voor huishoudens
met een digitale meter. Met een thuisbatterij kun je deze kosten volledig vermijden. Het capaciteitstarief is een tarief dat je extra zal betalen waneer je
gedurende een kwartier veel energie van het net verbruikt, dit is het zogenaamde piekverbruik.
Huiseigenaars die een thuisbatterij hebben kunnen hun piekverbruik afvlakken door de in de thuisbatterij opgeslagen energie in te zetten wanneer er verschillende
energieverslindende apparaten tegelijkertijd werken. Op de thuisbatterij kan je zelfs
instellen dat dit automatisch gebeurt, zodat je nooit een bepaald piekverbruik overstijgt.
De details en extra kosten van het capaciteitstarief vind je onderaan de pagina.</Typography>
export const capaciteitShort: string = 'Een batterij verlaagd de extra kost van het capaciteitsTarief.';
export const levensduurTitle: string = 'Levensduur';
export const levensduur: JSX.Element = <Typography>Op de meeste thuisbatterijen staat een garantie van 10 jaar.
Dit betekent dat je verzekerd bent dat de batterij na 10 jaar gebruik nog steeds minstens 60% van zijn capaciteit zal behouden.
Dit is echter in extreme omstandigheden gerekend, en in de meeste gevallen zal je na 10 jaar gebruik nog steeds meer dan 60% van de
capaciteit van je batterij overhouden.
 De levensduur van de batterij wordt beïnvloed door verschillende factoren, die je onderaan de pagina kunt vinden.</Typography>
export const levensduurShort: string = 'Op de meeste thuisbatterijen staat een garantie van 10 jaar.';
export const veiligheidTitle: string = 'Veiligheid';
export const veiligheid: JSX.Element = <Typography>Wanneer u een thuisbatterij door ons laat plaatsen, kunt u met een gerust hart slapen.
Een thuisbatterij is volledig veilig wanneer deze correct is aangesloten en geplaatst in een geventileerde ruimte. Wat betreft veiligheid bestaat
vaak de misvatting dat thuisbatterijen uit hetzelfde materiaal bestaan als die in uw smartphone of laptop. Een thuisbatterij bestaat echter uit LiFePO4
 (LFP) cellen die veel moeilijker
ontvlambaar zijn dan de Lithium polymeer (Li-pol) cellen die in uw smartphone of laptop worden gebruikt.</Typography>
export const veiligheidShort: string = 'Een thuisbatterij is volledig veilig wanneer deze correct is aangesloten en geplaatst is in een geventileerde ruimte:';
export const capaciteitstariefQuestion: string = 'Het capaciteitstarief, wat is het en hoe kan ik het vermijden?';
const link = <Link
    href={"https://www.vreg.be/nl/veelgestelde-vragen/hoeveel-capaciteitstarief-betaalt-u-voor-een-kilowatt#:~:text=btw)%20aan%20capaciteitstarief.,btw)%20extra."}>hier</Link>;
export const capaciteitstariefAnswer1: string = 'Buiten de hoeveelheid energie die je betaalt, word je vanaf 2023 ook belast op je maximale piekverbruik per maand. Verbruik je op een bepaald moment gedurende 15 minuten zeer veel energie in je huis, dan zal je hierop extra belast worden. Deze kost kan je echter volledig vermijden met een thuisbatterij. Het minimale capaciteitstarief dat iedereen moet betalen, ook al verbruik je geen elektriciteit, komt overeen met een piekverbruik van 2,5 kW per maand. De tarieven van het capaciteitstarief voor 1 kW piek liggen op 40,4 euro (excl. btw) per jaar; op maandbasis is dat 3,37 euro (excl. btw) voor 2023. Het minimale capaciteitstarief dat betaald moet worden en dat overeenkomt met 2,5 kw, bedraagt dus jaarlijks gemiddeld 101 euro (excl. btw). De tarieven verschillen van netbeheerder tot netbeheerder; het tarief dat bij jou van toepassing is, kan je '
    + link + 'terug vinden. -> “https://www.vreg.be/nl/veelgestelde-vragen/hoeveel-capaciteitstarief-betaalt-u-voor-een-kilowatt#:~:text=btw)%20aan%20capaciteitstarief.,btw)%20extra.”';
export const capaciteitstariefAnswer2: string = 'De levensduur van een batterij is afhankelijk van het aantal laadcycli. De meeste thuisbatterijen kunnen ongeveer 6000 keer worden opgeladen voordat ze minder goed functioneren. Meestal zal 1 cyclus per dag worden gebruikt, overdag laadt de batterij op en in de avond ontlaadt deze. Als we de rekensom maken, zien we dat een batterij ongeveer 16 jaar kan meegaan voordat deze nog slechts 60% van zijn totale capaciteit bezit. Ook kun je de levensduur van je batterij verlengen door deze niet volledig op te laden en ook niet volledig te ontladen. Wanneer je de capaciteit van de batterij bijvoorbeeld tussen de grenzen van 80% en 20% houdt, is dit beter voor de levensduur van je batterij.';

export const opslagExpandedAnswer: JSX.Element = <ExpandedAnswer title={opslagTitle}>{opslag}</ExpandedAnswer>
export const capaciteitExpandedAnswer: JSX.Element = <ExpandedAnswer
    title={capaciteitTitle}>{capaciteit}</ExpandedAnswer>
export const levensduurExpandedAnswer: JSX.Element = <ExpandedAnswer
    title={levensduurTitle}>{levensduur}</ExpandedAnswer>
export const veiligheidExpandedAnswer: JSX.Element = <ExpandedAnswer
    title={veiligheidTitle}>{veiligheid}</ExpandedAnswer>

export const batText1 = <>Buiten de hoeveelheid energie die je betaalt word je vanaf 2023 ook belast op je maximale piek
    verbruik per maand. Verbruik je op een bepaald moment gedurende 15 minuten zeer veel energie in je
    huis, dan zal je hierop extra belast worden. Deze extra kost kan je echter volledig vermijden met
    een
    thuisbatterij. Het minimale capaciteitstarief dat iedereen moet betalen ook al verbruik je geen
    elektriciteit komt overeen met een piekverbruik van 2,5 kW per maand. De tarieven van het
    capaciteitstarief voor 1 kW piek liggen op 40,4 euro (excl. btw) per jaar; op maandbasis is dat 3,37
    euro (excl.
    btw). Het minimale capaciteitstarief dat overeenkomt met 2.5 kW bedraagt dus jaarlijks gemiddeld 101
    euro (excl. btw). De tarieven verschillen van netbeheerder tot netbeheerder,
    het tarief dat bij jou van toepassing is kan je <Link
        sx={{display: 'inline-block', color: lightGreen, textDecorationColor: lightGreen}}
        href={"https://www.vreg.be/nl/veelgestelde-vragen/hoeveel-capaciteitstarief-betaalt-u-voor-een-kilowatt#:~:text=btw)%20aan%20capaciteitstarief.,btw)%20extra."}> hier </Link> terug
    vinden.</>;
export const prod1 = <>De Growatt APX-batterij is een modulair opslagsysteem voor zonne-energie, dat achteraf nog makkelijk
    kan worden uitgebreid door het bijplaatsen van extra batterijmodules. Het Growatt APX-batterijsysteem wordt
    geleverd met geavanceerde energiebeheerfuncties en intelligente controlesystemen. Deze functies optimaliseren
    het energieverbruik door de batterijlading te regelen op basis van de elektriciteitsvraag, de piekverbruiken,
    het al dan niet elektrisch laden en andere parameters. Hierdoor kan het systeem de energie-efficiëntie
    verbeteren en de kosten van het energieverbruik verlagen.</>
export const prod2 = <>De Huawei Luna batterij is een modulair opslagsysteem voor zonne-energie met een geavanceerd
    beheersysteem voor een snelle en betrouwbare werking van de batterij, waardoor gebruikers kunnen profiteren van
    een betrouwbare en constante energievoorziening. De Huawei Luna batterij is uitgerust met slimme
    energiebeheerfuncties die het energieverbruik optimaliseren en de energiekosten verlagen. Dit resulteert in een
    verhoogde zelfconsumptie van zonne-energie en een verminderde afhankelijkheid van het elektriciteitsnet.</>


export const answersBatterijen: ClickableAnswer[] = [
    {title: opslagTitle, explanation: opslagShort, icon: energy, answer: opslag, expandedAnswer: opslagExpandedAnswer},
    {title: capaciteitTitle, explanation: capaciteitShort, icon: capacity, answer: capaciteit,expandedAnswer: capaciteitExpandedAnswer},
    {title: levensduurTitle, explanation: levensduurShort, icon: checkboxAnswer, answer: levensduur,expandedAnswer: levensduurExpandedAnswer},
    {title: veiligheidTitle, explanation: veiligheidShort, icon: safety, answer: veiligheid, expandedAnswer: veiligheidExpandedAnswer},
]

import {Box} from "@mui/material";
import brent from "../../images/brent.webp";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import ModalCard from "../modals/ModalCard";


const HeaderOverOnsMd = (props: { blocks: { title: string, explanation: string, icon: JSX.Element }[] }) => {
    return (
        <Box
            m="auto"
            justifyContent="center"
            sx={{
                display: {xs: 'none', md: 'block'},
                width: '100%', backgroundImage: `url(${brent})`,
                backgroundRepeat: "no-repeat", backgroundAttachment: 'fixed', pb: 10, backgroundSize: 'cover'
            }}>
            <Box sx={{width: '100%', height: 450, position: 'relative'}}>
                <Grid
                    container
                    direction="row"
                    xs={6}
                    spacing={2}
                    sx={{width: '50%', position: 'absolute', left: '20%', top: '15%'}}>
                    <Grid
                        item
                        xs={12}>
                        <Typography sx={{fontSize: 70, lineHeight: 1, color: 'white'}}>
                            Wie zijn wij?
                        </Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box m="auto" sx={{display: {xs: 'none', md: 'flex'}, width: '80%'}}>
                <Grid container
                      justifyContent="center"
                      spacing={2}
                      sx={{pt: 6, pb: 4}}>
                    {props.blocks.map(block => (
                        <ModalCard
                            title={block.title}
                            icon={block.icon}
                        >
                            {block.explanation}
                        </ModalCard>
                    ))}
                </Grid>
            </Box>
        </Box>
    );
}

export default HeaderOverOnsMd
import {Box} from "@mui/material";
import {lightGreen} from "../colors/colors";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import ArrowBullet from "./ArrowBullet";
import React from "react";

const ArrowBulletList = () => {
    let arrowBullet1 = <>U vraagt via de website een gratis advies moment aan. Hierop zullen wij binnen de 24
                                                             uur reageren met een voorstel om op plaatsbezoek te komen.</>;
    let arrowBullet2 = <>Als u de afspraak bevestigt komt één van onze energie experten tot bij u
                                                             thuis voor het maken van een grondige energie analyse.</>;
    let arrowBullet3 = <>Uiterlijk 24 uur na het plaatsbezoek krijgt u uw persoonlijke energieanalyse van uw huis.
                                                             Bij deze energieanalyse krijgt u ook van ons een vrijblijvende offerte waarbij u zelf bepaalt wat u er achteraf mee doet.</>;
    let title1 = 'Vastleggen van gratis advies moment:';
    let title2 = 'Plaatsbezoek van een expert:';
    let title3 = 'Ontvangst van uw persoonlijke energieanalyse:';
    return (
        <React.Fragment>
            <Box m="auto" justifyContent="center">
                <Box m="auto"
                     justifyContent="center"
                     sx={{
                         my: 8,
                         width: '50%',
                         ml: '20%',
                         pl: '7%',
                         border: 2,
                         borderRadius: '16px',
                         borderColor: lightGreen,
                         display: {xs: 'none', md: 'block'}
                     }}>
                    <Typography
                        variant="h4"
                        m="auto"
                        sx={{
                            borderWidth: '4px', borderColor: lightGreen,
                            display: {xs: 'none', md: 'flex', color: 'black', width: '65%'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'white',
                            mt: 2,
                            mb: 2,
                            mr: '30%'
                        }}
                    >
                        Hoe loopt ons gratis advies?
                    </Typography>
                    <Grid
                        container
                        sx={{}}
                    >
                        <Grid
                            item
                        >
                            <Grid
                                container
                                spacing={0}
                            >

                                <ArrowBullet title={title1}>
                                    {arrowBullet1}
                                </ArrowBullet>
                                <ArrowBullet title={title2}>
                                    {arrowBullet2}
                                </ArrowBullet>
                                <ArrowBullet title={title3}>
                                    {arrowBullet3}
                                </ArrowBullet>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Box m="auto" justifyContent="center">
                <Box m="auto"
                     justifyContent="center"
                     sx={{
                         my: 8,
                         width: '88%',
                         ml: '5%',
                         pl: '2%',
                         border: 2,
                         borderRadius: '16px',
                         borderColor: lightGreen,
                         display: {xs: 'block', md: 'none'}
                     }}>
                    <Typography
                        variant="h5"
                        m="auto"
                        sx={{
                            borderWidth: '4px', borderColor: lightGreen,
                            display: { color: 'black', width: '65%'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            color: 'white',
                            mt: 2,
                            mb: 2,
                            mr: '30%'
                        }}
                    >
                        Hoe loopt ons gratis advies?
                    </Typography>
                    <Grid
                        container
                        sx={{}}
                    >
                        <Grid
                            item
                        >
                            <Grid
                                container
                                spacing={0}
                            >

                                <ArrowBullet title={title1}>
                                    {arrowBullet1}
                                </ArrowBullet>
                                <ArrowBullet title={title2}>
                                    {arrowBullet2}
                                </ArrowBullet>
                                <ArrowBullet title={title3}>
                                    {arrowBullet3}
                                </ArrowBullet>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default ArrowBulletList;
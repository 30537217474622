import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {white} from "../../colors/colors";
import Grid from "@mui/material/Grid";

const ModalCard = (props: { title: string, children: any, icon: JSX.Element }) => {

    return (
        <Grid
            item
            alignItems="top"
            justifyContent="center"
            xs={3}
            sx={{pt: 2, pb: 2, ml: '1%', mr: '1%', backgroundColor: white, borderRadius: '16px', opacity: '90%'}}
        >
            <Grid
                container
                spacing={0}
                justifyItems="center"
                alignItems="center"
                direction="column"
            >
                <Grid
                    item
                >
                    <Box m="auto">
                        {props.icon}
                    </Box>
                </Grid>
                <Grid
                    item
                >
                    <Typography m="auto" gutterBottom align="center"  variant="h5"
                                sx={{width: '60%', height: '100%', mb: 1, mr: '20%'}}>
                        {props.title}
                    </Typography>
                    <Typography m="auto" variant="body1" align="justify" justifyContent="center"
                                color="text.secondary"
                                sx={{width: '90%', height: '100%'}}>
                        {props.children}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ModalCard;
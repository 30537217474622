import Icon from '@mdi/react';
import {
    mdiHomeBatteryOutline,
    mdiSolarPanelLarge,
    mdiEvStation,
    mdiCheckboxMarkedCircleAutoOutline,
    mdiBatteryCheck,
    mdiReceiptTextClockOutline,
    mdiShieldCheckOutline,
    mdiEarHearing,
    mdiShuffleVariant,
    mdiTruckDeliveryOutline,
    mdiSeal,
    mdiCashClock,
    mdiNoteEditOutline,
    mdiCheckDecagram,
    mdiArrowDownBoldBox,
    mdiCashRemove,
    mdiCarElectric,
    mdiCashFast,
    mdiMeterElectricOutline,
    mdiSecurity,
    mdiScaleBalance,
    mdiCircleOpacity,
    mdiAccountKey,
    mdiSunCompass,
    mdiArrowRightBottomBold
} from '@mdi/js';
import {lightGreen} from "../colors/colors";

const color = lightGreen;
const size: number = 3;

export const homeBattery = <Icon path={mdiHomeBatteryOutline} size={size} color={color}/>;
export const solarPanel = <Icon path={mdiSolarPanelLarge} size={size} color={color}/>
export const chargingStation = <Icon path={mdiEvStation} size={size} color={color}/>

export const homeBatteryMini = <Icon path={mdiHomeBatteryOutline} size={2} color={color}/>;
export const solarPanelMini = <Icon path={mdiSolarPanelLarge} size={2} color={color}/>
export const chargingStationMini = <Icon path={mdiEvStation} size={2} color={color}/>

export const checkbox = <Icon path={mdiCheckboxMarkedCircleAutoOutline} size={1} color={color}/>
export const checkboxAnswer = <Icon path={mdiCheckboxMarkedCircleAutoOutline} size={size} color={color}/>

export const energy = <Icon path={mdiBatteryCheck} size={size} color={color}/>;

export const capacity = <Icon path={mdiReceiptTextClockOutline} size={size} color={color}/>

export const safety = <Icon path={mdiShieldCheckOutline} size={size} color={color}/>

export const ear = <Icon path={mdiEarHearing} size={size} color={color}/>
export const seal = <Icon path={mdiSeal} size={size} color={color}/>
export const truck = <Icon path={mdiTruckDeliveryOutline} size={size} color={color}/>
export const shuffle = <Icon path={mdiShuffleVariant} size={size} color={color}/>
export const cashClock = <Icon path={mdiCashClock} size={size} color={color}/>
export const note = <Icon path={mdiNoteEditOutline} size={size} color={color}/>
export const checkDeca = <Icon path={mdiCheckDecagram} size={size} color={color}/>
export const arrowDown = <Icon path={mdiArrowDownBoldBox} size={size} color={color}/>
export const cashRemove = <Icon path={mdiCashRemove} size={size} color={color}/>
export const car = <Icon path={mdiCarElectric} size={size} color={color}/>
export const cashFast = <Icon path={mdiCashFast} size={size} color={color}/>
export const meter = <Icon path={mdiMeterElectricOutline} size={size} color={color}/>
export const security = <Icon path={mdiSecurity} size={size} color={color}/>
export const scale = <Icon path={mdiScaleBalance} size={size} color={color}/>
export const circle = <Icon path={mdiCircleOpacity} size={size} color={color}/>
export const account = <Icon path={mdiAccountKey} size={size} color={color}/>
export const compass = <Icon path={mdiSunCompass} size={size} color={color}/>

export const bulletArrow = <Icon path={mdiArrowRightBottomBold} size={3} color={color}/>

import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AnswerGrid from "../AnswerGrid";
import React from "react";
import {ClickableAnswer} from "../../types";


const HeaderXs = (props: { answers: ClickableAnswer[] , title: JSX.Element, image: string}) => {
    return (
        <Box
            m="auto"
            justifyContent="center"
            sx={{
                display: {xs: 'flex', md: 'none'},
                width: '100%',
                backgroundImage: `url(${props.image})`,
                backgroundRepeat: "no-repeat",
                backgroundAttachment: 'fixed',
                backgroundSize: 'cover',
            }}>
            <Box sx={{width: '100%', minHeight: 500, position: 'relative'}}>
                <Grid
                    container
                    direction="row"
                    spacing={2}
                    sx={{width: '60%', position: 'absolute', left: '10%', top: '2%'}}>
                    <Grid
                        item
                        xs={12}>
                        <Typography sx={{fontSize: 35, lineHeight: 1, color: 'white'}}>
                            {props.title}
                        </Typography>
                    </Grid>
                </Grid>
                <Box m="auto" justifyContent="center"
                     sx={{display: {xs: 'flex', md: 'none'}, width: '100%', mt: '300px'}}>
                    <AnswerGrid answers={props.answers}/>
                </Box>
            </Box>
        </Box>
    );
}

export default HeaderXs;
import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import React from "react";

const Question = (props: {children: string}) => {
    return (
        <Box>
            <Typography
                variant="h4"
                m="auto"
                sx={{
                    display: {xs: 'none', md: 'flex', color: 'black', width: '65%'},
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'white',
                    mt: 2,
                    mb: 2
                }}
            >
                {props.children}
            </Typography>
            <Typography
                variant="h5"
                m="auto"
                sx={{
                    display: { xs: 'flex', md: 'none', color:'black', width: '90%'},
                    flexGrow: 1,
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    color: 'white',
                    mt: 2,
                }}
            >
                {props.children}
            </Typography>
        </Box>
    );
}

export default Question;
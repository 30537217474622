import {Box, CardActionArea, CardContent, Paper} from "@mui/material";
import React from "react";
import {lightGreen, white} from "../../colors/colors";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import {useNavigate} from "react-router-dom";

const TabCard = (props: {
    title: string,
    children: string,
    icon: JSX.Element,
    miniIcon: JSX.Element,
    onClick: (page: string) => void
}) => {
    const navigate = useNavigate();
    const onClick = () => {
        props.onClick(props.title)
        navigate('/' + props.title.toLowerCase());
    }
    return (
        <React.Fragment>
            <Paper
                sx={{
                    minWidth: 250,
                    maxWidth: 345,
                    height: '100%',
                    borderRadius: '16px',
                    boxShadow: 0,
                    display: {xs: 'none', md: 'flex'},
                    border: 1,
                    borderColor: lightGreen
                }}
                style={{background: white}}
                onClick={onClick}>
                <CardActionArea>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Box m="auto" justifyContent="center" sx={{mt: 2}}>
                            {props.icon}
                        </Box>
                        <CardContent>
                            <Typography gutterBottom align="center" variant="h5" component="div">
                                {props.title}
                            </Typography>
                            <Typography variant="body2" align="justify" color="text.secondary">
                                {props.children}
                            </Typography>
                        </CardContent>
                    </Grid>
                </CardActionArea>
            </Paper>
            <Paper
                sx={{
                    minWidth: 250,
                    borderRadius: '16px',
                    boxShadow: 0,
                    display: {xs: 'flex', md: 'none'},
                    mb: -5,
                    border: 1,
                    borderColor: lightGreen
                }}
                style={{background: white}}
                onClick={onClick}>
                <CardActionArea>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid
                            item
                            xs={4}
                        >
                            <Box m="auto" justifyContent="center" sx={{pl: 2}}>
                                {props.miniIcon}
                            </Box>
                        </Grid>
                        <Grid
                            item
                            xs={8}
                        >
                            <CardContent>
                                <Typography gutterBottom align="center" variant="body1" sx={{pt: '10%'}}>
                                    {props.title}
                                </Typography>
                            </CardContent>
                        </Grid>
                    </Grid>
                </CardActionArea>
            </Paper>
        </React.Fragment>
    );
}

export default TabCard;
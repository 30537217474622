import React from "react";
import {Box} from "@mui/material";
import {white} from "../colors/colors";
import Offerte from "../component/offerte/Offerte";
import ResponsiveAppBar from "../component/ResponsiveAppBar";
import Footer from "../component/Footer";
import {Outlet} from "react-router-dom";


const Layout = (props: { page: string, setCurrentPage: (page: string) => void , onClickOpen: () => void, onClickClose: () => void, open: boolean}) => {

    const [showOfferte, setShowOfferte] = React.useState<null | boolean>(false);
    let height: string = (window.outerHeight / 16 - 6).toString();

    const onShowOfferte = () => {
        setShowOfferte(true);
        console.log(showOfferte);
    }

    const onClick = (page: string) => {
        props.setCurrentPage(page);
        window.scrollTo({top: 0, left: 0})
    }

    const onClickClose = () => {
        props.onClickClose()
    }

    const onClickOpen = () => {
        props.onClickOpen()
    }



    const pages = ['Home', 'Laadpalen', 'Batterijen', 'Zonnepanelen', 'Over Ons'];

    return (
        <Box sx={{backgroundColor: white, minHeight: height}}>
            <Offerte onClickClose={onClickClose} open={props.open}/>
            <ResponsiveAppBar pages={pages} currentPage={props.page} onClick={onClick}
                              onShowOfferte={onShowOfferte} onClickOpen={onClickOpen}/>
            <Box sx={{minHeight: height + 'vw', mt: 6}}>
                <Outlet/>
            </Box>
            <Footer/>
        </Box>
    );
}

export default Layout;
import home from "../../images/home1.webp";
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import cert from "../../images/rescertnlv5.png";
import Button from "@mui/material/Button";
import {lightGreen} from "../../colors/colors";
import products from "../../images/home2.png";
import mainLogo from "../../images/logo5v2.png";
import React from "react";

const HeaderHomeMd = (props: {onClickOpen: () => void}) => {

    const onClickOpen = () => {
        props.onClickOpen()
    }


    return (
        <Box
                m="auto"
                justifyContent="center"
                sx={{
                display: {xs: 'none', md: 'block'},
                width: '100%', backgroundImage: `url(${home})`,
                backgroundRepeat: "no-repeat", pb: 20, backgroundAttachment: 'fixed', backgroundSize: 'cover'
            }}>
            <Box sx={{width: '100%', height: 600, position: 'relative'}}>
                <Grid
                    container
                    direction="row"
                    xs={6}
                    spacing={2}
                    sx={{width: '100%', pt: '250px', pl: '20%'}}>
                    <Grid
                        item
                        xs={12}>
                        <Typography sx={{fontSize: 70, lineHeight: 1}}>
                            Uw partner voor <br/> betrouwbare <br/> energie
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <img src={cert} alt="logo" style={{width: '50%'}}/>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{mt: 6}}>
                        <Button sx={{backgroundColor: lightGreen, width: '300px', borderRadius: '16px'}}  style={{textTransform: 'none'}}  onClick={onClickOpen}>
                            <Typography align="justify" color="black" sx={{fontSize: 30}}>
                                Gratis advies
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Box sx={{left: '60%', right: '8%', top: '40%', position: 'absolute', width: '600px'}}>
                    <img src={products} alt="logo" style={{width: '55%'}}/>
                </Box>
                <Box sx={{left: '20%', top: 35, position: 'absolute',}}>
                    <img src={mainLogo} alt="logo" style={{width: '80%', marginLeft: '10%'}}/>
                </Box>
            </Box>
        </Box>
    );
}

export default HeaderHomeMd;
import {ClickableAnswer} from "../types";
import {arrowDown, cashClock, cashRemove, checkDeca, compass, note} from "../icons/icons";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import React from "react";
import ExpandedAnswer from "../component/ExpandedAnswer";
import {lightGreen} from "../colors/colors";
import {Box} from "@mui/material";
import Calculator from "../component/Calculator";

export const why: string = 'Waarom investeren in zonnepanelen?'

export const terugverdientijdTitle: string = 'Terugverdientijd';
export const terugVerdienTijdShort: JSX.Element = <Typography>De terugverdientijd van de meeste zonnepaneel systemen ligt <b> tussen  de 4 en 8 jaar.</b></Typography>;
export const terugverdientijd: JSX.Element = <Typography>De terugverdientijd van de meeste zonnepaneelsystemen
ligt tussen de 4 en 8 jaar. Als je weet dat je op de meeste zonnepanelen een garantie van 30 jaar krijgt, dan is dit een aardige investering.
Wanneer je een offerte aanvraagt bij ons, dan maken wij gratis een uitgebreide analyse over de terugverdientijd van je systeem. Meer info hierover vind je onderaan de pagina.
    {/*Meer weten? Klink dan <Link sx={{display: 'inline-block'}}*/}
    {/*                                                             href={""}> hier </Link>.*/}
</Typography>
export const terugverdienTijdAnswer = <ExpandedAnswer title={terugverdientijdTitle}>{terugverdientijd}</ExpandedAnswer>

export const subsidieTitle: string = 'Subsidie';
export const subsidieShort: JSX.Element = <Typography>U krijgt van de vlaamse overheid een premie van tot wel <b>750 euro!</b></Typography>
export const subsidie: JSX.Element = <Typography>Aangezien wij een erkend installateur zijn, krijgt u van de
Vlaamse overheid een premie tot wel 750 euro. Deze premie is afhankelijk van het aantal zonnepanelen dat u installeert
op uw dak. Zo krijgt u een premie van 150 euro per kWp voor een
geïnstalleerd vermogen van 0 tot 4 kWp. Daarna krijgt u van 4 tot 6 kWp een premie van 75 euro per kWp geïnstalleerd vermogen.
    {/*Deze premie kan u <Link sx={{display: 'inline-block'}}*/}
    {/*                        href={"https"}> hier </Link> terug vinden*/}
</Typography>;
export const subsidieAnswer =
    <Box>
        <ExpandedAnswer title={subsidieTitle}>{subsidie}</ExpandedAnswer>
        <Calculator/>
    </Box>

export const normenTitle: string = 'Hulp bij EPB-norm';
export const normenShort: string = 'Bij nieuwbouw en renovatie kunnen zonnepanelen je helpen om de EPB-normen te behalen.'
export const normen: JSX.Element = <Typography>Bij nieuwbouw en renovatie kunnen zonnepanelen je helpen om aan de EPB-normen
te voldoen. Per zonnepaneel kun je rekenen op een daling van het E-peil met 1 tot 1,5 punten.</Typography>
export const normenAnswer = <ExpandedAnswer title={normenTitle}>{normen}</ExpandedAnswer>
export const tariefTitle: string = 'Verlaagd btw tarief';
export const tariefShort: JSX.Element = <Typography>Van de regering krijgt u een <b>verlaagd btw tarief van 6% i.p.v. 21% in 2023!</b></Typography>
export const tarief: JSX.Element = <Typography>Van de regering krijgt u een verlaagd btw-tarief van 6% in plaats van 21% en dit nog tot eind 2023.</Typography>
export const tariefAnswer = <ExpandedAnswer title={tariefTitle}>{tarief}</ExpandedAnswer>

export const prosumentTitle: string = 'Geen prosumententarief';
export const prosumentShort: JSX.Element = <Typography>Met een digitale meter betaalt u <b>geen prosumententarief!</b></Typography>
export const prosument: JSX.Element = <Typography>Aangezien u een digitale meter moet hebben wanneer u nu
zonnepanelen laat plaatsen, betaalt u geen prosumententarief, wat jaarlijks voor sommige gezinnen met zonnepanelen en een
terugdraaiende teller kan oplopen tot 1000 euro per jaar. U vindt het bedrag van het prosumententarief <Link
        sx={{display: 'inline-block', color: lightGreen, textDecorationColor: lightGreen}}
        href={"https://www.vlaanderen.be/prosumententarief-voor-eigenaars-van-zonnepanelen-windmolens-en-wkk-installaties-kleine-installaties-met-terugdraaiende-teller/berekening-van-het-prosumententarief"}> hier </Link> terug.</Typography>;
export const prosumentAnswer = <ExpandedAnswer title={prosumentTitle}>{prosument}</ExpandedAnswer>

export const vergoedingTitle: string = 'Vergoeding voor opgewekte energie';
export const vergoedingShort: string = 'Voor de opgewekte energie die u naar het net stuurt zal je een\n' +
    '    vergoeding ontvangen.';
export const vergoeding: JSX.Element = <Typography>Voor de opgewekte energie die u naar het net stuurt,
ontvangt u een vergoeding. Deze vergoeding zal van uw energiefactuur worden afgetrokken. Zorg er echter voor dat u
bij uw energieleverancier een teruglevercontract heeft. Mocht dit niet het geval zijn, dan kunt u hen hierover het beste informeren.</Typography>
export const vergoedingAnswer = <ExpandedAnswer title={vergoedingTitle}>{vergoeding}</ExpandedAnswer>

export const answersZonnepanelen: ClickableAnswer[] = [
    {title: terugverdientijdTitle, explanation: terugVerdienTijdShort, expandedAnswer: terugverdienTijdAnswer, icon: cashClock, answer: terugverdientijd},
    {title: subsidieTitle, explanation: subsidieShort, expandedAnswer: subsidieAnswer, icon: note, answer: subsidie},
    {title: normenTitle, explanation: normenShort, expandedAnswer: normenAnswer, icon: checkDeca, answer: normen},
    {title: tariefTitle, explanation: tariefShort, expandedAnswer: tariefAnswer, icon: arrowDown, answer: tarief},
    {title: prosumentTitle, explanation: prosumentShort, expandedAnswer: prosumentAnswer, icon: cashRemove, answer: prosument},
    {title: vergoedingTitle, explanation: vergoedingShort, expandedAnswer: vergoedingAnswer, icon: compass, answer: vergoeding},
];
export const factoren: string = 'Welke factoren hebben een invloed op de terugverdientijd en hoe bepalen wij deze:';
export const factorenAnswer: string = 'De terugverdientijd van je zonnepaneel installatie is afhankelijk van verschillende factoren zoals:';
export const bullet1: string = 'De plaatsingswijze, zo zullen zonnepanelen die naar het zuiden gericht zijn in de winter meer energie kunnen opwekken. Ook de hellingshoek waaronder de zonnepanelen geplaatst zijn en de aanwezigheid van schaduw op de panelen spelen een rol. Geen nood, is je dak niet volledig naar het zuiden gericht of valt er soms schaduw op dan zijn zonnepanelen nog steeds een zeer interessante oplossing met een goede terugverdientijd.';
export const bullet2: string = 'Werk je van thuis, dan zal een deel van je verbruik ook overdag vallen juist wanneer de zonnepanelen je woning van energie voorzien. Dit zorgt dan weer voor een snellere terugverdientijd.';
export const bullet3: string = 'Verwarm je je huis elektrisch of met een warmtepomp, dan kan je met de energie uit je zonnepanelen je woning rechtstreeks gaan verwarmen.';

export const bullet4: string = 'Rijd je met een elektrische wagen zo ja, dan kan je met de energie uit je zonnepanelen rechtstreeks je wagen thuis opladen. Dit maakt dat je veel geld kan besparen aan het tankstation!';
export const prod1 = <>Dit paneel gebruikt n-type monokristallijn silicium als halfgeleidermateriaal, wat een aanzienlijke verbetering is ten opzichte van de traditionele p-type zonnepanelen. Met dit n-type zonnepaneel kan een garantie van 30 jaar worden verkregen! Wij geven de voorkeur aan deze panelen vanwege hun esthetische zwarte uitstraling. Bovendien kiezen wij altijd voor panelen met een zo dik mogelijke glaslaag, wat het paneel versterkt en voor een betere bescherming tegen hagel en onweer zorgt.</>
export const prod2 = <>Wat een bifacial paneel echt onderscheidt, is zijn technologie waarbij deze panelen ook via de achterkant elektriciteit kunnen opwekken. Dit zorgt voor nog een extra opbrengst aan energie. Deze technologie komt tot zijn recht op platte of doorzichtige daken, waarbij er nog een deel van het gereflecteerde en diffuus licht op de achterkant van de panelen kan vallen. Aangezien deze panelen ook uit n-type cellen bestaan, is de levensduur van deze panelen ook weer hoger, waardoor een garantie van 30 jaar wordt verkregen!</>

export const textBox1 = <>Wij bij Anewpower maken voor iedereen die dat wil een persoonlijke en uitgebreide energieanalyse.
    In deze energieanalyse nemen we de volgende zaken op: uw totale en toekomstige energieverbruik, uw
    verbruiksprofiel,
    de actuele en toekomstige energieprijzen, de ligging van het dak,
    de aanwezigheid van schaduw en obstakels op het dak, en de keuze om al dan niet een batterij te
    installeren.</>;
export const textBox2 = <>De plaatsingswijze speelt een rol, waarbij de hellingshoek waaronder de zonnepanelen geplaatst zijn
    en de
    aanwezigheid van schaduw op de panelen van belang zijn. Geen zorgen, staat je dak niet onder de best
    mogelijke hellingshoek van 35 graden en valt er soms schaduw op je dak, dan zijn zonnepanelen nog steeds
    een zeer interessante oplossing met een goede terugverdientijd.
    De nieuwste zonnepanelen kunnen licht rechtstreeks opvangen onder een hoek van ongeveer 45 graden.
    Ook kan er via de nieuwste technologieën gewerkt worden met optimizers. Deze optimizers worden onder de
    panelen geplaatst, waardoor het negatieve effect van schaduw op je zonnepanelen sterk verminderd wordt.</>;
export const textBox3 = <>De oriëntatie van je zonnepanelen speelt een rol. Zonnepanelen die naar het zuiden gericht zijn,
    leveren
    in de winter, wanneer er minder zonlicht is, de meeste energie op. Met panelen die naar het zuiden zijn
    gericht, gecombineerd met een thuisbatterij, kan je ervoor zorgen dat je woning bijna energieneutraal
    wordt. Dit
    komt doordat je met de thuisbatterij de energie die je overdag hebt opgewekt kan gebruiken in de ochtend
    en
    avond wanneer de zon niet schijnt. Zonnepanelen die naar het oosten en westen zijn gericht, leveren
    daarentegen meer energie op tijdens de
    ochtend en avond. Deze opstelling zorgt voor een eerder gespreide energieopwekking gedurende de
    volledige dag.
    Het enige nadeel van deze opstelling is dat in de winter de zon alleen hoog aan de hemel komt te staan
    in
    het zuiden, waardoor de zonnepanelen in het oosten en westen minder energie kunnen opwekken dan panelen
    die
    naar het zuiden gericht zijn.</>;
export const textBox4 = <>Als je van thuis uit werkt, zal een deel van je verbruik ook overdag plaatsvinden, juist wanneer
    de zonnepanelen je woning van energie voorzien. Dit zorgt weer voor een snellere terugverdientijd
    aangezien je de opgewekte energie van je zonnepanelen meteen kan verbruiken en dus geen energie
    uit het net hoeft te halen.</>;
export const textBox5 = <>Verwarm je je huis elektrisch of met een warmtepomp?
    Dan kan je met de energie uit je zonnepanelen je woning rechtstreeks gaan verwarmen.</>;
export const textBox6 = <>Rijd je met een elektrische auto? Zo ja, dan kan je met de energie uit je zonnepanelen rechtstreeks
    je
    auto thuis opladen. Dit bespaart je veel geld aan publiek laden! Ook al rijd je met een
    bedrijfswagen en heb je een laadpas, toch is het interessant om met je eigen opgewekte
    energie je auto thuis op te laden via een laadpaal. Zo zal je baas de energie die je in je
    auto laadt, terugbetalen, wat voor jou een extra opbrengst kan betekenen.</>;

import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import TabCard from "./TabCard";
import React from "react";
import {
    chargingStation,
    chargingStationMini,
    homeBattery,
    homeBatteryMini,
    solarPanel,
    solarPanelMini
} from "../../icons/icons";

const tabCards: { title: string, explanation: string, icon: JSX.Element, miniIcon: JSX.Element }[] = [
    {title: 'Laadpalen', explanation: '', icon: chargingStation, miniIcon: chargingStationMini},
    {title: 'Batterijen', explanation: '', icon: homeBattery, miniIcon: homeBatteryMini},
    {title: 'Zonnepanelen', explanation: '', icon: solarPanel, miniIcon: solarPanelMini},
]

const Tabs = (props: { onClick: (page: string) => void }) => {

    const onClick = (title: string) => {
        props.onClick(title);
    }
    return (
        <React.Fragment>
            <Box sx={{pt: 4, pb: 4, display: {xs: 'none', md: 'flex'}}}>
                <Box m="auto" sx={{width: '80%'}}>
                    <Grid container justifyContent="center" spacing={8}>
                        {tabCards.map((tabCard) => (
                            <Grid key={tabCard.title} item>
                                <TabCard
                                    title={tabCard.title}
                                    icon={tabCard.icon}
                                    onClick={onClick}
                                    miniIcon={tabCard.miniIcon}
                                >
                                    {tabCard.explanation}
                                </TabCard>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
            <Box sx={{pt: 4, pb: 9, display: {xs: 'flex', md: 'none'}}}>
                <Box m="auto" sx={{width: '80%'}}>
                    <Grid container justifyContent="center" spacing={8}>
                        {tabCards.map((tabCard) => (
                            <Grid key={tabCard.title} item>
                                <TabCard
                                    title={tabCard.title}
                                    icon={tabCard.icon}
                                    onClick={onClick}
                                    miniIcon={tabCard.miniIcon}
                                >
                                    {tabCard.explanation}
                                </TabCard>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default Tabs;
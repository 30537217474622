import {Box, CardActionArea} from "@mui/material";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

const ClickableModalCardMd = (props: {
    title: string,
    children: string,
    answer: JSX.Element,
    icon: JSX.Element,
    onClick: (answer: { expandedAnswer: JSX.Element, title: string }) => void,
    color: string,
    width: number
}) => {

    const onClick = () => {
        props.onClick({expandedAnswer: props.answer, title: props.title});
    }
    return (
        <Grid
            item
            alignItems="top"
            justifyContent="center"
            xs={props.width}
            // sx={{display: "flex", flexDirection: "column"}}
        >
            <CardActionArea
                onClick={onClick}
                sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: props.color,
                    borderRadius: '16px',
                    ml: '1%',
                    mr: '1%',
                    pb: 3,
                    opacity: '90%'
                }}>
                <Grid
                    container
                    spacing={0}
                    justifyItems="center"
                    alignItems="center"
                    direction="column"
                >
                    <Grid
                        item
                    >
                        <Box m="auto">
                            {props.icon}
                        </Box>
                    </Grid>
                    <Grid
                        justifyContent="center"
                        item
                    >
                        <Typography m="auto" gutterBottom align="center"  variant="h5"
                                    sx={{width: '90%', height: '100%', mb: 1, mr: '5%'}}>
                            {props.title}
                        </Typography>
                        <Typography m="auto" variant="body1" align="justify" justifyContent="center"
                                    color="text.secondary"
                                    sx={{width: '60%', height: '100%'}}>
                            {props.children}
                        </Typography>
                    </Grid>
                </Grid>
            </CardActionArea>
        </Grid>
    );
}

export default ClickableModalCardMd;
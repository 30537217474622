import {Box, Divider} from "@mui/material";
import batterijHeader from "../images/battery1.webp";
import Question from "../text/Question";
import {
    answersBatterijen,
    batText1,
    capaciteitstariefAnswer2,
    capaciteitstariefQuestion,
    prod1,
    prod2
} from "../textblocks/BatterijenText";
import {ClickableAnswer} from "../types";
import TextBox from "../text/TextBox";
import React from "react";
import {darkGreen, lightGreen} from "../colors/colors";
import Grid from "@mui/material/Grid";
import Product from "../component/Product";
import batterij1 from "../images/batterij1.png";
import batterij2 from "../images/batterij2.png";
import VoorbeeldMerken from "../component/VoorbeeldMerken";
import HeaderMd from "../component/headers/HeaderMd";
import HeaderXs from "../component/headers/HeaderXs";

const answers: ClickableAnswer[] = answersBatterijen
const title = <>Waarom investeren <br/>in batterijen?</>;

const Batterijen = () => {

    return (
        <Box>
            <HeaderMd answers={answers} title={title} image={batterijHeader} width={3}/>
            <HeaderXs answers={answers} title={title} image={batterijHeader}/>
            <Box sx={{backgroundColor: darkGreen, pt: 4, pb: 4}}>
                <VoorbeeldMerken>
                    Voorbeeld batterijen:
                </VoorbeeldMerken>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="top"
                        justifyContent="center"
                    >
                        <Product
                            image={batterij1}
                            title={'Growatt APX-batterij'}
                            info={prod1}/>
                        <Product
                            image={batterij2}
                            title={'Huawei Luna batterij'}
                            info={prod2}/>
                    </Grid>
                </Grid>
            </Box>
            <Question>{capaciteitstariefQuestion}</Question>
            <Box sx={{display: {xs: 'none', md: 'block'}, width: '65%', pl: '17.5%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <Box sx={{display: {xs: 'block', md: 'none'}, width: '100%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                <TextBox>
                    {batText1}
                </TextBox>
            </Box>
            <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                <TextBox>
                    {batText1}
                </TextBox>
            </Box>
            <Question>Hoe de levensduur van je batterij verhogen?</Question>
            <Box id={"levensduur"} sx={{display: {xs: 'none', md: 'block'}, width: '65%', pl: '17.5%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <Box sx={{display: {xs: 'block', md: 'none'}, width: '100%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <TextBox>{capaciteitstariefAnswer2}</TextBox>
        </Box>
    );
}

export default Batterijen;
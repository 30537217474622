import React, {useState} from 'react';
import './App.css';
import Home from "./pages/home/Home";
import OverOns from "./pages/OverOns";
import Zonnepanelen from "./pages/Zonnepanelen";
import Batterijen from "./pages/Batterijen";
import Laadpalen from "./pages/Laadpalen";
import {HashRouter, Route, Routes} from "react-router-dom";
import Layout from "./pages/Layout";


function App() {

    const [currentPage, setcurrentPage] = useState('Home')
    const [open, setOpen] = React.useState(false);

    const onClickOpen = () => {
        setOpen(true);
    }

    const onClickClose = () => {
        setOpen(false);
    }


    const onClick = (page: string) => {
        setcurrentPage(page)
        window.scrollTo({top: 0, left: 0})
    }


    return (
        <HashRouter>
            <Routes>
                <Route path="/" element={<Layout page={currentPage} setCurrentPage={setcurrentPage} onClickClose={onClickClose} onClickOpen={onClickOpen} open={open}/>}>
                    <Route index element={<Home onClick={onClick} onClickOpen={onClickOpen}/>}/>
                    <Route path="home" element={<Home onClick={onClick} onClickOpen={onClickOpen}/>}/>
                    <Route path="laadpalen" element={<Laadpalen/>}/>
                    <Route path="batterijen" element={<Batterijen/>}/>
                    <Route path="zonnepanelen" element={<Zonnepanelen/>}/>
                    <Route path="overons" element={<OverOns/>}/>
                </Route>
            </Routes>
        </HashRouter>
    );
}

export default App;

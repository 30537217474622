
export const intro1: string = 'Bij Anewpower gaan we verder dan alleen het leveren van zonnepanelen; we bieden ook persoonlijk advies aan voor iedereen die dat wenst. Ons doel is om samen te werken met onze klanten door hen op de hoogte te brengen van de nieuwste wetten en technologieën, zodat we tot het beste systeem kunnen komen dat past bij hun behoeften. Ons ervaren team van experts voert altijd eerst een grondige energieanalyse uit, samen met de klant. We nemen factoren zoals het huidige en toekomstige energieverbruik, dakoriëntatie, verwarming en koeling, het elektrisch laden van uw auto en andere mogelijke besparingen in overweging. Op basis hiervan bepalen we wat u precies nodig heeft van de installatie en maken we een gepaste offerte.'
export const intro2: string = 'Bij Anewpower eindigt onze verantwoordelijkheid niet bij het afleveren van de installatie. We zorgen er eerst voor dat alle subsidies waar u recht op heeft in orde zijn. Vervolgens bieden we de best mogelijke na-verkoop service. Onze toewijding aan kwaliteit en klanttevredenheid betekent dat we altijd klaar staan om antwoorden te geven op uw vragen op elke stap van uw duurzame reis.'
export const intro3: string = 'Daarnaast fungeert Anewpower ook als een advies- en ingenieursbureau, waarbij u ook een beroep kan doen op ons voor het leiden van grote projecten. We bieden ook services voor het ontwerpen, ontwikkelen, implementeren en opvolgen van grote zonneparken. Onze expertise en technische kennis stellen ons in staat om grootschalige solar-projecten tot een succesvol einde te brengen, waarbij we duurzame energieoplossingen bieden die een positieve impact hebben op het milieu en de samenleving. Bij Anewpower streven we naar een groenere toekomst voor iedereen, en we zijn vastbesloten om onze klanten te ondersteunen in hun streven naar duurzame energie.'


export const eerlijkheidTitle: string = 'Eerlijkheid';

export const eerlijkheid: string = 'We vinden een eerlijke en open communicatie naar onze klanten zeer belangrijk. Zo willen wij steeds een eerlijk antwoord bieden op al u vragen.'

export const transparantieTitle: string = 'Transparantie';

export const transparantie: string = 'Transparantie naar onze klanten toe vinden we zeer belangrijk. Dit op vlak van prijs en de haalbaarheid van het project.';

export const vertrouwenTitle: string = 'Vertrouwen';

export const vertrouwen: string = 'Zelf weten we hoe moeilijk het vinden van een betrouwbare firma kan zijn. Hierdoor vragen wij onze klanten steeds voor het schrijven van een review. Zo kunnen toekomstige klanten ons leren kennen op een eerlijke en transparantie manier.';

export const outro: string = 'Achter de oprichting van Anwepower zelf zit Brent Van De Vyver, een ingenieur met ervaring in de solar, batterijen en de laadinfrastructuur wereld. Buiten het uitvoeren van engineering en design is hij ook zeker niet bang van het leiden en controleren van projecten op de werf zelf, wat een enorme meerwaarde betekent voor Anewpower. Graag straalt hij leiderschap, eerlijkheid, transparantie en vertrouwen uit voor zowel de klant als zijn team.';
import * as React from 'react';
import {useRef, useState} from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Box, Checkbox, ListItem, Typography} from "@mui/material";
import {cancel, lightGreen} from '../../colors/colors';
import emailjs from "emailjs-com";
import MenuItem from "@mui/material/MenuItem";
import Grid from "@mui/material/Grid";
import ReCAPTCHA from "react-google-recaptcha";

const Offerte = (props: {open: boolean, onClickClose: () => void}) => {
    const [content, setContent] = useState('');
    const [dakType, setDakType] = useState('Daktype');
    const [dakBedekking, setDakBedekking] = useState('Dakbedekking');
    const [kennen, setKennen] = useState('');
    const [naam, setNaam] = useState('');
    const [voornaam, setVoornaam] = useState('');
    const [email, setEmail] = useState('');
    const [telefoon, setTelefoon] = useState('');
    const [straatnaam, setStraatnaam] = useState('');
    const [huisnummer, setHuisnummer] = useState('');
    const [postcode, setPostcode] = useState('');
    const [gemeente, setGemeente] = useState('');
    const [verbruik, setVerbruik] = useState('');
    // const [allowClosure, setAllowClosure] = useState(false);

    const [checkedZonnepanelen, setCheckedZonnepanelen] = React.useState(false);
    const [checkedBatterijen, setCheckedBatterijen] = React.useState(false);
    const [checkedLaadpalen, setCheckedLaadpalen] = React.useState(false);

    const captchaRef: React.RefObject<ReCAPTCHA> = useRef<ReCAPTCHA>(null);

    const handleChangeZonnepanelen = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedZonnepanelen(event.target.checked);
    };
    const handleChangeBatterijen = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedBatterijen(event.target.checked);
    };
    const handleChangeLaadpalen = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheckedLaadpalen(event.target.checked);
    };


    const contentChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setContent(event.target.value)
    }
    const dakTypeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDakType(event.target.value as string);
    }
    const dakBedekkingChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDakBedekking(event.target.value as string);
    }
    const kennenChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKennen(event.target.value)
    }
    const naamChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNaam(event.target.value)
    }
    const voornaamChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVoornaam(event.target.value)
    }
    const emailChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }
    const telefoonChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTelefoon(event.target.value)
    }
    const straatnaamChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStraatnaam(event.target.value)
    }
    const huisnummerChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setHuisnummer(event.target.value)
    }
    const postcodeChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPostcode(event.target.value)
    }
    const gemeenteChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setGemeente(event.target.value)
    }
    const verbruikChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVerbruik(event.target.value)
    }

    const handleClose = () => {
        props.onClickClose();
    };


    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        console.log('submit started')
        const token = await captchaRef.current!.executeAsync();
        console.log('token: ', token)

        token && sendMail(token);
        // if (allowClosure) {
        //     setOpen(false);
        // }
    }

    const sendMail = async (token: string) => {
        console.log('sending mail')
        let contantParam = {
            zonnepanelen: checkedZonnepanelen ? 'Ja' : 'Nee',
            laadpalen: checkedLaadpalen ? 'Ja' : 'Nee',
            batterijen: checkedBatterijen ? 'Ja' : 'Nee',
            content: content,
            dakType: dakType,
            dakBedekking: dakBedekking,
            kennen: kennen,
            naam: naam,
            voornaam: voornaam,
            email: email,
            telefoon: telefoon,
            straatnaam: straatnaam,
            huisnummer: huisnummer,
            postcode: postcode,
            gemeente: gemeente,
            verbruik: verbruik,
            'g-recaptcha-response': token

        }
        console.log('param: ', contantParam)
        emailjs.init("bd18DqDW8cq1lIgaX");
        console.log('init complere')
        emailjs.send('service_uje7ze7', 'template_6o5ixer', contantParam)
            .then(() => {
                captchaRef.current!.reset();
                console.log('successfull sending')
                // setAllowClosure(true)
                handleClose()
            }, err => {
                console.log(err)
                // setAllowClosure(false)
                //maybe add some pop up for problems
            })

    }

    return (
        <Box>
            <Dialog open={props.open} onClose={handleClose} sx={{display: {xs: 'none', md: 'block'}}}>
                <form onSubmit={onSubmit}>
                    <DialogTitle>Energieanalyse</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Vraag hier uw gratis energieanalyse aan.
                        </DialogContentText>
                        <Grid
                            container
                            justifyContent="center"
                            direction="row"
                            spacing={0}
                            sx={{pt: 2, pb: 2}}>
                            <Grid
                                item
                                xs={4}>
                                <ListItem alignItems="center">
                                    <Checkbox
                                        checked={checkedZonnepanelen}
                                        onChange={handleChangeZonnepanelen}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                    <Typography>
                                        Zonnepanelen
                                    </Typography>
                                </ListItem>
                            </Grid>
                            <Grid
                                item
                                xs={4}>
                                <ListItem alignItems="center">
                                    <Checkbox
                                        checked={checkedBatterijen}
                                        onChange={handleChangeBatterijen}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                    <Typography>
                                        Batterijen
                                    </Typography>
                                </ListItem>
                            </Grid>
                            <Grid
                                item
                                xs={4}>
                                <ListItem alignItems="center">
                                    <Checkbox
                                        checked={checkedLaadpalen}
                                        onChange={handleChangeLaadpalen}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                    <Typography>
                                        Laadpalen
                                    </Typography>
                                </ListItem>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}>
                            {(checkedBatterijen || checkedZonnepanelen) &&
                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="jaarverbruik"
                                        label="Jaarverbruik in kWh"
                                        type="number"
                                        fullWidth
                                        onChange={verbruikChangeHandler}
                                    />
                                </Grid>}
                            {checkedZonnepanelen &&
                                <Grid
                                    item
                                    xs={6}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="daktype"
                                        label="Daktype"
                                        type="string"
                                        fullWidth
                                        select
                                        onChange={dakTypeChangeHandler}
                                    >
                                        <MenuItem value={"Hellend dak"}>Hellend dak</MenuItem>
                                        <MenuItem value={"Plat dak"}>Plat dak</MenuItem>
                                        <MenuItem value={"Andere"}>Andere</MenuItem>
                                    </TextField>
                                </Grid>}
                            {checkedZonnepanelen &&
                                <Grid
                                    item
                                    xs={6}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="dakbedekking"
                                        label="Dakbedekking"
                                        type="string"
                                        fullWidth
                                        select
                                        onChange={dakBedekkingChangeHandler}
                                    >
                                        <MenuItem value={"Dakpannen"}>Dakpannen</MenuItem>
                                        <MenuItem value={"Leien"}>Leien</MenuItem>
                                        <MenuItem value={"Staal dak"}>Staal dak</MenuItem>
                                        <MenuItem value={"Andere"}>Andere</MenuItem>
                                    </TextField>
                                </Grid>}
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="kennen"
                                    label="Van waar kent u ons?"
                                    type="text"
                                    fullWidth
                                    onChange={kennenChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="naam"
                                    label="Naam"
                                    type="text"

                                    fullWidth
                                    onChange={naamChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="voornaam"
                                    label="Voornaam"
                                    type="text"

                                    fullWidth
                                    onChange={voornaamChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="email"
                                    label="E-mail"
                                    type="email"

                                    fullWidth
                                    onChange={emailChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="telefoon"
                                    label="Telefoon"
                                    type="text"

                                    fullWidth
                                    onChange={telefoonChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="straatnaam"
                                    label="Straatnaam"
                                    type="text"

                                    fullWidth
                                    onChange={straatnaamChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="huisnummer"
                                    label="Huisnummer + toevoeging"
                                    type="text"

                                    fullWidth
                                    onChange={huisnummerChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="postcode"
                                    label="Postcode"
                                    type="number"

                                    fullWidth
                                    onChange={postcodeChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="gemeente"
                                    label="Gemeente"
                                    type="text"

                                    fullWidth
                                    onChange={gemeenteChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    id="content"
                                    margin="dense"
                                    label="Geef ons meer uitleg of aanvullende informatie"
                                    name="Inhoud"
                                    multiline
                                    rows={8}
                                    fullWidth
                                    onChange={contentChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                            >
                                <ReCAPTCHA
                                    ref={captchaRef}
                                    size="invisible"
                                    sitekey="6Lcf3kAnAAAAAJMWNpRUJkw-GvE3x6FWMKb6vwwP"
                                />
                            </Grid>
                        </Grid>
                        <DialogActions>
                            <Button sx={{backgroundColor: cancel}} onClick={handleClose}>
                                <Typography align="justify" color="black">
                                    Annuleren
                                </Typography>
                            </Button>
                            <Button sx={{backgroundColor: lightGreen}} type="submit">
                                <Typography align="justify" color="black">
                                    Verzenden
                                </Typography>
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
            <Dialog open={props.open} onClose={handleClose} fullScreen sx={{display: {xs: 'flex', md: 'none'}}}>
                <form onSubmit={onSubmit}>
                    <DialogTitle>Energieanalyse</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Vraag hier uw gratis energieanalyse aan.
                        </DialogContentText>
                        <Grid
                            container
                            justifyContent="center"
                            direction="row"
                            spacing={0}
                            sx={{pt: 2, pb: 2}}>
                            <Grid
                                item
                                xs={12}>
                                <ListItem alignItems="center">
                                    <Checkbox
                                        checked={checkedZonnepanelen}
                                        onChange={handleChangeZonnepanelen}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                    <Typography>
                                        Zonnepanelen
                                    </Typography>
                                </ListItem>
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <ListItem alignItems="center">
                                    <Checkbox
                                        checked={checkedBatterijen}
                                        onChange={handleChangeBatterijen}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                    <Typography>
                                        Batterijen
                                    </Typography>
                                </ListItem>
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <ListItem alignItems="center">
                                    <Checkbox
                                        checked={checkedLaadpalen}
                                        onChange={handleChangeLaadpalen}
                                        inputProps={{"aria-label": "primary checkbox"}}
                                    />
                                    <Typography>
                                        Laadpalen
                                    </Typography>
                                </ListItem>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            spacing={2}>
                            {(checkedBatterijen || checkedZonnepanelen) &&
                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="jaarverbruik"
                                        label="Jaarverbruik in kWh"
                                        type="number"
                                        fullWidth
                                        onChange={verbruikChangeHandler}
                                    />
                                </Grid>}
                            {checkedZonnepanelen &&
                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="daktype"
                                        label="Daktype"
                                        type="string"
                                        fullWidth
                                        select
                                        onChange={dakTypeChangeHandler}
                                    >
                                        <MenuItem value={"Hellend dak"}>Hellend dak</MenuItem>
                                        <MenuItem value={"Plat dak"}>Plat dak</MenuItem>
                                        <MenuItem value={"Andere"}>Andere</MenuItem>
                                    </TextField>
                                </Grid>}
                            {checkedZonnepanelen &&
                                <Grid
                                    item
                                    xs={12}>
                                    <TextField
                                        autoFocus
                                        margin="dense"
                                        id="dakbedekking"
                                        label="Dakbedekking"
                                        type="string"
                                        fullWidth
                                        select
                                        onChange={dakBedekkingChangeHandler}
                                    >
                                        <MenuItem value={"Dakpannen"}>Dakpannen</MenuItem>
                                        <MenuItem value={"Leien"}>Leien</MenuItem>
                                        <MenuItem value={"Staal dak"}>Staal dak</MenuItem>
                                        <MenuItem value={"Andere"}>Andere</MenuItem>
                                    </TextField>
                                </Grid>}
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="kennen"
                                    label="Van waar kent u ons?"
                                    type="text"
                                    fullWidth
                                    onChange={kennenChangeHandler}
                                    sx={{mt: 1.5}}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="naam"
                                    label="Naam"
                                    type="text"

                                    fullWidth
                                    onChange={naamChangeHandler}
                                    sx={{mt: 1.5}}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="voornaam"
                                    label="Voornaam"
                                    type="text"

                                    fullWidth
                                    onChange={voornaamChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="email"
                                    label="E-mail"
                                    type="email"

                                    fullWidth
                                    onChange={emailChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="telefoon"
                                    label="Telefoon"
                                    type="text"

                                    fullWidth
                                    onChange={telefoonChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="straatnaam"
                                    label="Straatnaam"
                                    type="text"

                                    fullWidth
                                    onChange={straatnaamChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="huisnummer"
                                    label="Huisnummer + toevoeging"
                                    type="text"

                                    fullWidth
                                    onChange={huisnummerChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="postcode"
                                    label="Postcode"
                                    type="number"

                                    fullWidth
                                    onChange={postcodeChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    id="gemeente"
                                    label="Gemeente"
                                    type="text"

                                    fullWidth
                                    onChange={gemeenteChangeHandler}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    id="content"
                                    margin="dense"
                                    label="Geef ons meer uitleg of aanvullende informatie"
                                    name="Inhoud"
                                    multiline
                                    rows={2}
                                    fullWidth
                                    onChange={contentChangeHandler}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            xs={12}
                        >
                            <ReCAPTCHA
                                ref={captchaRef}
                                size="invisible"
                                sitekey="6Lcf3kAnAAAAAJMWNpRUJkw-GvE3x6FWMKb6vwwP"
                            />
                        </Grid>
                        <DialogActions>
                            <Button sx={{backgroundColor: cancel}} onClick={handleClose}>
                                <Typography align="justify" color="black">
                                    Annuleren
                                </Typography>
                            </Button>
                            <Button sx={{backgroundColor: lightGreen}} type="submit">
                                <Typography align="justify" color="black">
                                    Verzenden
                                </Typography>
                            </Button>
                        </DialogActions>
                    </DialogContent>
                </form>
            </Dialog>
        </Box>
    );
}

export default Offerte;
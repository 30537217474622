import {Box, Divider} from "@mui/material";
import laadpaalHeaderMd from "../images/laadpaalHeader.webp";
import laadpaalHeaderXs from "../images/laadpaalHeaderXs.webp";
import Grid from "@mui/material/Grid";
import Product from "../component/Product";
import laadPaal1 from '../images/laadpaal1.png';
import laadPaal2 from '../images/laadpaal2.png';
import {darkGreen, lightGreen} from "../colors/colors";
import React from "react";
import {ClickableAnswer} from "../types";
import {answer, answersLaadpalen, prod1, prod2} from "../textblocks/LaadPalen";
import VoorbeeldMerken from "../component/VoorbeeldMerken";
import TextBox from "../text/TextBox";
import Question from "../text/Question";
import HeaderMd from "../component/headers/HeaderMd";
import HeaderXs from "../component/headers/HeaderXs";

const answers: ClickableAnswer[] = answersLaadpalen
const title = <>Waarom investeren <br/>in een laadpaal?</>;


const Laadpalen = () => {

    return (
        <Box>
            <HeaderMd answers={answers} title={title} image={laadpaalHeaderMd} width={3}/>
            <HeaderXs answers={answers} title={title} image={laadpaalHeaderXs}/>

            <Box m="auto" justifyContent="center" sx={{backgroundColor: darkGreen, pt: 4, pb: 4}}>
                <VoorbeeldMerken>
                    Voorbeeld laadpalen:
                </VoorbeeldMerken>
                <Grid item xs={12}>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="top"
                        justifyContent="center"
                    >
                        <Product
                            image={laadPaal1}
                            title={'Huawei laadstation'}
                            info={prod1}/>
                        <Product
                            image={laadPaal2}
                            title={'Growatt laadstation'}
                            info={prod2}/>
                    </Grid>
                </Grid>
            </Box>
            <Question>Wat is een bidirectioneel laadstation?</Question>
            <Box id={"levensduur"} sx={{display: {xs: 'none', md: 'block'}, width: '65%', pl: '17.5%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <Box sx={{display: {xs: 'block', md: 'none'}, width: '100%'}}>
                <Divider variant="middle" sx={{borderBottomWidth: 2, backgroundColor: lightGreen}}/>
            </Box>
            <TextBox>
                {answer}
            </TextBox>
        </Box>
    );
}

export default Laadpalen;
import React from "react";
import {Box} from "@mui/material";


const BrandLogo = (props: { src: string }) => {
    return (
        <React.Fragment>
            <Box sx={{display: {xs: 'none', md: 'flex'}, ml: '2%', pr: '2%'}}>
                <img src={props.src} alt="Logo"/>
            </Box>
            <Box sx={{display: {xs: 'flex', md: 'none'}, ml: '45%', mr: '45%', mt: 4, mb: 4}}>
                <img src={props.src} alt="Logo"/>
            </Box>
        </React.Fragment>
    );
}

export default BrandLogo;
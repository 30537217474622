import Typography from "@mui/material/Typography";
import ExpandedAnswer from "../component/ExpandedAnswer";
import React from "react";
import Link from "@mui/material/Link";
import {ClickableAnswer} from "../types";
import {car, cashFast, meter, security} from "../icons/icons";
import {lightGreen} from "../colors/colors";

export const consumptieTitle = 'Zelfconsumptie';
export const consumptieShort = 'Laad je wagen op met energie die je rechtstreeks opwekt!';
export const consumptie = <Typography>
    Laad je auto op met energie die je rechtstreeks opwekt uit je zonnepanelen door voor een slimme laadpaal te kiezen.
    Deze slimme laadpalen laden de wagen dan enkel op met energie die je zelf hebt opgewekt. Hierbij haal je dus geen
    energie uit het net en kan
    je kostenloos je wagen opladen met energie die je zelf produceert.
    Hierdoor kan je flink wat besparen op publieke laadpalen of aan de pomp moest je nog geen elektrische wagen
    bezitten, heb jij de berekening al gemaakt?
</Typography>
export const consumptieAnswer = <ExpandedAnswer title={consumptieTitle}>{consumptie}</ExpandedAnswer>

export const premieTitle = 'Belastingvermindering';
export const premieShort = 'Ontvang een belastingvermindering van tot wel 540 euro in 2023!';
export const premie = <Typography>
    Ontvang een belastingvermindering van tot wel 30% op de aankoopprijs van je laadpaal voor een bedrag tot en met 1800
    euro in 2023. Let op, in 2024 zal
    deze dalen tot 15%. Ook kan je kiezen voor de plaatsing van een bidirectioneel laadstation.
    Hierbij is het bedrag waarvoor de belastingvermindering telt verhoogd tot 8.000 euro in 2023 per laadstation.
    Onderaan de pagina vind je terug wat een bidirectioneel laadstation is.
    De voorwaarden vind je <Link sx={{display: 'inline-block', color: lightGreen, textDecorationColor: lightGreen}}
                                 href={"https://www.vlaanderen.be/fiscaal-voordeel-voor-plaatsing-van-laadstation"}> hier </Link> terug.
</Typography>
export const premieAnswer = <ExpandedAnswer title={premieTitle}>{premie}</ExpandedAnswer>

export const terugbetalingTitle = 'Terugbetaling';
export const terugbetalingShort = 'Vergoeding van de werkgever voor je elektriciteitskosten.';
export const terugbetaling = <Typography>
    Als u uw elektrische auto thuis oplaadt met een laadpaal die een MID energiemeter heeft, dan kan de werkgever de
    elektriciteitskosten vergoeden.
    U dient als gebruiker van de bedrijfswagen dan wel het geldende tarief middels een specificatie te overleggen aan de
    werkgever.
    De meeste bedrijven maken gebruik van het CREG tarief om uw energie te vergoeden. U vindt
    deze tarieven <Link sx={{display: 'inline-block', color: lightGreen, textDecorationColor: lightGreen}}
                        href={"https://www.creg.be/nl/a-z-index/oplaadkostenvergoeding-bedrijfswagens"}> hier </Link> terug.
</Typography>
export const terugbetalingAnswer = <ExpandedAnswer title={terugbetalingTitle}>{terugbetaling}</ExpandedAnswer>

export const veiligheidTitle = 'Veiligheid en snelheid';
export const veiligheidShort = 'Het laden met een laadpaal is veiliger en sneller dan het laden uit een stopcontact.';
export const veiligheid = <Typography>
    Het laden met een laadpaal is veiliger en sneller dan het laden uit een stopcontact. Laden uit een stopcontact kan
    voor problemen zorgen wanneer voor lange tijd geladen wordt via een
    stopcontact.
    Dit gevaar komt voort uit het feit dat het stopcontact waarin u de lader van uw wagen steekt, een slecht contact kan
    geven.
    Dit slechte contact kan leiden tot oververhitting en mogelijk brand.
    Ook kunnen de laders die je in het stopcontact steekt slechts laden tegen een snelheid van 2,3 kw. Bij een laadpaal
    kan deze
    snelheid gaan tot 11 of zelfs 22 kW, wat betekent dat je wagen veel sneller kan opladen.
    Als voorbeeld, een gemiddelde wagen met een batterij van 64 kWh zal via een stopcontact ongeveer 27 uur moeten
    opladen om zijn
    batterij van 0 tot 100% te vullen. Met een laadpaal van 11 kW zal dit nog slechts 5,5 uur bedragen.
</Typography>
export const veiligheidAnswer = <ExpandedAnswer title={veiligheidTitle}>{veiligheid}</ExpandedAnswer>
export const prod1 = <>Aangezien dit een slimme laadpaal is, kan deze communiceren met uw smartphone, slimme woningapparaten en
    het energienetwerk. Hierdoor heeft u volledige controle over uw laadsessies, kunt u laadtijden inzien en profiteren
    van slimme energiebeheeropties om uw energieverbruik te optimaliseren. Ook bezit deze laadpaal een
    MID-gecertificeerde energiemeter om het energieverbruik per transactie nauwkeurig te berekenen.</>
export const prod2 = <>Het Growatt laadstation biedt professionele en geavanceerde technologie aan om energievoorziening te
    bieden aan elektrische voertuigen. Het is compatibel met alle EV-merken. Bij dit laadstation kan ook een
    MID-gecertificeerde energiemeter geplaatst worden om het energieverbruik te monitoren en u te wapenen tegen de
    toekomstige wetgeving.</>
export const answer = <>Een bidirectioneel laadstation, ook wel bekend als Vehicle-to-Grid (V2G) laadstation, is een
    geavanceerd type laadpunt voor elektrische voertuigen dat meer functionaliteit biedt dan alleen het
    opladen van de batterij van het voertuig. In tegenstelling tot een traditioneel eenrichtingslaadstation
    dat alleen stroom van het elektriciteitsnet naar het voertuig levert, kan een bidirectioneel laadstation
    ook stroom van het voertuig terug naar het elektriciteitsnet sturen.
    Met andere woorden, een bidirectioneel laadstation maakt het mogelijk dat de energie die is opgeslagen
    in de batterij van het elektrische voertuig terug kan worden geleverd aan het elektriciteitsnet wanneer
    dat nodig is. Dit kan bijvoorbeeld handig zijn tijdens piekuren van de elektriciteitsvraag of om
    hernieuwbare energie uit het voertuig te gebruiken om het elektriciteitsnet te ondersteunen.</>;

export const answersLaadpalen: ClickableAnswer[] = [
    {
        title: consumptieTitle,
        explanation: consumptieShort,
        icon: car,
        expandedAnswer: consumptieAnswer,
        answer: consumptie
    },
    {title: premieTitle, explanation: premieShort, icon: cashFast, expandedAnswer: premieAnswer, answer: premie},
    {
        title: terugbetalingTitle,
        explanation: terugbetalingShort,
        icon: meter,
        expandedAnswer: terugbetalingAnswer,
        answer: terugbetaling
    },
    {
        title: veiligheidTitle,
        explanation: veiligheidShort,
        icon: security,
        expandedAnswer: veiligheidAnswer,
        answer: veiligheid
    }]
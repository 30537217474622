import {Box} from "@mui/material";
import Typography from "@mui/material/Typography";
import {white} from "../../colors/colors";
import Grid from "@mui/material/Grid";

const ModalCardMobile = (props: { title: string, children: any, icon: JSX.Element , width: string, opacity: string }) => {

    return (
        <Box
            sx={{width: props.width, backgroundColor: white, borderRadius: '16px', mt: 4, mb: 2,pb: 2, ml: '1%', mr: '1%', opacity: props.opacity}}>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
            >
                <Box m="auto">
                    {props.icon}
                </Box>
                <Box m="auto" sx={{width: '90%'}}>
                    <Typography gutterBottom align="center" variant="subtitle1" component="div">
                        {props.title}
                    </Typography>
                    <Typography variant="body2"  color="text.secondary">
                        {props.children}
                    </Typography>
                </Box>
            </Grid>
        </Box>
    );
}

export default ModalCardMobile;
import React from "react";
import Grid from "@mui/material/Grid";
import ModalCard from "./modals/ModalCard";
import {Box} from "@mui/material";
import {ClickableAnswer} from "../types";
import ModalCardMobile from "./modals/ModalCardMobile";

const AnswerGrid = (props: { answers: ClickableAnswer[] }) => {
    return (
        <React.Fragment>
            <Box m="auto" sx={{display: {xs: 'none', md: 'flex'}, width: '80%'}}>
                <Grid container>
                    <Grid
                        item
                        justifyContent="center"
                        xs={6}
                        sx={{pt: 6, pb: 6, xs: 'none', md: 'flex'}}>
                        {props.answers.map((answer) => (
                            <ModalCard
                                title={answer.title}
                                icon={answer.icon}
                            >
                                {answer.answer}
                            </ModalCard>
                        ))}
                    </Grid>
                </Grid>
            </Box>
            <Grid
                container
                justifyContent="center"
                sx={{display: {xs: 'flex', md: 'none'}, width: '100%'}}>
                {props.answers.map((answer) => (
                    <ModalCardMobile
                        title={answer.title}
                        icon={answer.icon}
                        width={'46%'}
                        opacity={'90%'}
                    >
                        {answer.answer}
                    </ModalCardMobile>
                ))}
            </Grid>
        </React.Fragment>

    );
}

export default AnswerGrid;
import {Box, Card} from "@mui/material";
import Grid from "@mui/material/Grid";
import ModalCard from "./modals/ModalCard";
import {ear, seal, shuffle, truck} from "../icons/icons";
import ModalCardMobile from "./modals/ModalCardMobile";


const TwoTwoGrid = () => {
    return (
        <Box m="auto">
            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                <Grid
                    container
                    spacing={0}
                    component={Card}
                    direction="column"
                    alignItems="top"
                    justifyContent="center"
                    flexDirection="column"
                    xs
                >
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="top"
                        justifyContent="center"
                    >
                        <ModalCard
                            title={'Het luisteren naar onze klanten'}
                            icon={ear}
                        >
                           Bij Anewpower luisteren we naar onze klanten en passen we de installatie aan naar uw individuele behoeften.
                           Hoe moeilijk de installatie ook is, met ons gespecialiseerde team zoeken we naar de beste oplossing.
                        </ModalCard>
                        <ModalCard
                            title={'Beste prijs-kwaliteit gegarandeerd'}
                            icon={seal}
                        >
                            Wij bij Anewpower staan we erop dat onze klanten steeds de beste prijs-kwaliteitverhouding krijgen.
                            Indien u echter een offerte heeft ontvangen van een concurrent die onder onze prijs duikt, laat ons
                             dan samen beide offertes eens in detail bekijken en aanpassen waar nodig.
                        </ModalCard>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="top"
                        justifyContent="center"
                    >
                        <ModalCard
                            title={'Leveren van topkwaliteit'}
                            icon={truck}
                        >
                            Zelf weten we hoe belangrijk de kwaliteit is van de systemen die we leveren.
                            Wij garanderen u het beste systeem en dit doen we door te werken met enkel de beste merken en materialen.
                        </ModalCard>
                        <ModalCard
                            title={'Flexibiliteit'}
                            icon={shuffle}
                        >
                            Wilt u materialen van een bepaald merk of van een bepaalde kwaliteit, geen probleem!
                            Wij passen ons aan en werken met de gevraagde materialen of merken die u zelf wilt.
                        </ModalCard>
                    </Grid>
                </Grid>
            </Box>
            <Box m="auto" justifyContent="center" sx={{display: {xs: 'flex', md: 'none'}}}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="top"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="top"
                        justifyContent="center"
                    >
                        <ModalCardMobile
                            title={'Het luisteren naar onze klanten'}
                            icon={ear}
                            width={'100%'}
                            opacity={'100%'}
                        >
                            Bij Anewpower luisteren we naar onze klanten en passen we de installatie aan naar u
                            individuele
                            noden. Hoe moeilijk de installatie ook is, met ons gespecialiseerd team zoeken we naar de
                            beste
                            oplosing.
                        </ModalCardMobile>
                        <ModalCardMobile
                            title={'Beste prijs kwaliteit gegarandeerd'}
                            icon={seal}
                            width={'100%'}
                            opacity={'100%'}
                        >
                            Wij bij Anewpower staan erop dat onze klanten steeds de beste prijs kwaliteit krijgen. Heeft
                            u
                            toch een offerte ontvangen van een concurrent die onder onze prijs duikt, laat ons dan samen
                            beide offertes eens in detail bekijken en aanpassen waar nodig.
                        </ModalCardMobile>
                        <ModalCardMobile
                            title={'Leveren van top kwaliteit'}
                            icon={truck}
                            width={'100%'}
                            opacity={'100%'}
                        >
                            Zelf weten we hoe belangrijk de kwaliteit is van de systemen die we leveren. Wij garanderen
                            u
                            het beste systeem en dit doen we door te werken met enkel de beste merken en materialen.
                        </ModalCardMobile>
                        <ModalCardMobile
                            title={'Flexibiliteit'}
                            icon={shuffle}
                            width={'100%'}
                            opacity={'100%'}
                        >
                            Wilt u materialen van een bepaald merk of van een bepaalde kwaliteit, geen probleem! Wij
                            passen
                            ons aan en werken met de gevraagde materialen of merken die u zelf verkiest.
                        </ModalCardMobile>
                    </Grid>
                </Grid>
            </Box>
        </Box>

    );
}

export default TwoTwoGrid;
import React from 'react';
import Button from "@mui/material/Button";
import {lightGreen, white} from "../colors/colors";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";

const calculateColor = (page: string, currentPage: string) : string => {
    if(page === currentPage) {
        return lightGreen;
    } else {
        return white;
    }
}


export const Page = (props: {page: string, handleCloseNavMenu: () => void, currentPage: string, onClick: (page: string) => void}) => {
    const navigate = useNavigate();
    const clickPage = () => {
        props.onClick(props.page);
        navigate('/' + props.page.replace(/ /g,'').toLowerCase());
    }

    return (
        <Button
            key={props.page}
            onClick={clickPage}
            sx={{ my: 2, color: calculateColor(props.page, props.currentPage), display: 'block', mr: 6 }}
        >
            <Typography sx={{fontSize: 18}}>
                {props.page}
            </Typography>
        </Button>
    );
}

export default Page;
import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import {Facebook, Twitter} from "@mui/icons-material";
import {Box} from "@mui/material";
import logo from '../images/logo5v2.png';

export default function Footer() {
    return (
        <Box
            component="footer"
            // position='fixed'
            sx={{
                bottom: 0,
                backgroundColor: (theme) =>
                    theme.palette.mode === "light"
                        ? theme.palette.grey[200]
                        : theme.palette.grey[800],
                p: 6,
            }}
        >
            <Container maxWidth="lg">
                <Grid container spacing={5}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Over Ons
                        </Typography>
                        <img src={logo} alt="logo" style={{width: '50%'}}/>
                        <Typography variant="body2" color="text.secondary">
                            Wij zijn Anewpower en wij staan in voor het leveren van uw betrouwbare energie.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Contacteer ons
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Kernenergiestraat 19 B16, 2610 Wilrijk
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Email: info@anewpower.be
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            Telefoon: +32 477214333
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" color="text.primary" gutterBottom>
                            Volg ons
                        </Typography>
                        <Link href="https://www.facebook.com/profile.php?id=100094059503192&" color="inherit">
                            <Facebook/>
                        </Link>
                        {/*<Link*/}
                        {/*    href="https://www.instagram.com/"*/}
                        {/*    color="inherit"*/}
                        {/*    sx={{ pl: 1, pr: 1 }}*/}
                        {/*>*/}
                        {/*    <Instagram />*/}
                        {/*</Link>*/}
                        <Link href="https://twitter.com/Anewpower136544" color="inherit">
                            <Twitter/>
                        </Link>
                    </Grid>
                </Grid>
                <Box mt={5}>
                    <Typography variant="body2" color="text.secondary" align="center">
                        {"Copyright © "}
                        <Link color="inherit" href="https://www.anewpower.be/">
                            Anewpower
                        </Link>{" "}
                        {new Date().getFullYear()}
                        {"."}
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
}
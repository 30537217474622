import {ClickableAnswer} from "../../types";
import React, {useState} from "react";
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import ClickableAnswerGrid from "../ClickableAnswerGrid";
import {darkGreen} from "../../colors/colors";

const noQuestion = <Box></Box>;

const HeaderMd = (props: { answers: ClickableAnswer[] , title: JSX.Element, image: string, width: number}) => {
    const [loadedAnswer, setLoadedAnswerPage] = useState(noQuestion);
    const [clickedQuestion, setClickedQuestionPage] = useState("");
    const onClick = (answer: { expandedAnswer: JSX.Element, title: string }) => {
        if (answer.expandedAnswer === loadedAnswer) {
            setLoadedAnswerPage(noQuestion);
            setClickedQuestionPage("")
        } else {
            setLoadedAnswerPage(answer.expandedAnswer);
            setClickedQuestionPage(answer.title);
        }
    }
    return (
        <React.Fragment>
            <Box
                m="auto"
                justifyContent="center"
                sx={{
                    display: {xs: 'none', md: 'block'},
                    width: '100%', backgroundImage: `url(${props.image})`,
                    backgroundRepeat: "no-repeat", backgroundAttachment: 'fixed', pb: 10, backgroundSize: 'cover'
                }}>
                <Box sx={{width: '100%', height: 450, position: 'relative'}}>
                    <Grid
                        container
                        direction="row"
                        xs={6}
                        spacing={2}
                        sx={{width: '50%', position: 'absolute', left: '20%', top: '15%'}}>
                        <Grid
                            item
                            xs={12}>
                            <Typography sx={{fontSize: 70, lineHeight: 1, color: 'white'}}>
                                {props.title}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box m="auto" sx={{display: {xs: 'none', md: 'flex'}, flexDirection: 'column'}}>
                    <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                        <ClickableAnswerGrid answers={props.answers} clickedQuestion={clickedQuestion} width={props.width}
                                             onClick={onClick}/>
                    </Box>
                </Box>
            </Box>
            <Box sx={{backgroundColor: darkGreen}}>
                <Box m="auto" sx={{width: '75%'}}>
                    {loadedAnswer}
                </Box>
            </Box>
        </React.Fragment>
    );
}

export default HeaderMd;
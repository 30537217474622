import Grid from "@mui/material/Grid";
import {Box} from "@mui/material";
import {ClickableAnswer} from "../types";
import {slightlyGreen, white} from "../colors/colors";
import React from "react";
import ClickableModalCardMd from "./modals/ClickableModalCardMd";

const ClickableAnswerGrid = (props: {
    answers: ClickableAnswer[],
    onClick: (answer: { expandedAnswer: JSX.Element, title: string }) => void,
    clickedQuestion: string,
    width: number
}) => {
    const onClick = (answer: { expandedAnswer: JSX.Element, title: string }) => {
        props.onClick(answer)
    }
    return (
        <Box>
            <Box m="auto" sx={{display: {xs: 'none', md: 'flex'}, width: '80%'}}>
                <Grid m="auto" item xs={12} sx={{width: '100%'}}>
                    <Grid
                        container
                        justifyItems="center"
                        alignItems="top-stretch"
                        direction="row"
                        spacing={2}>
                        {props.answers.map((answer) => (

                            <ClickableModalCardMd
                                title={answer.title}
                                icon={answer.icon}
                                onClick={onClick}
                                answer={answer.expandedAnswer}
                                color={answer.title === props.clickedQuestion ? slightlyGreen : white}
                                width={props.width}>
                                {answer.explanation}
                            </ClickableModalCardMd>

                        ))}
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default ClickableAnswerGrid;
import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Page from '../navigation/Page';
import PageMenuItem from "../navigation/PageMenuItem";
import {gray, lightGreen} from '../colors/colors';
import mainLogoGray from '../images/logobv3.png'
import OfferteForm from './offerte/OfferteForm';
import MenuItem from "@mui/material/MenuItem";
import Link from "@mui/material/Link";
import {useNavigate} from "react-router-dom";

// const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const ResponsiveAppBar = (props: {
    pages: string[],
    currentPage: string,
    onShowOfferte: () => void,
    onClick: (page: string) => void,
    onClickOpen: () => void
}) => {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const navigate = useNavigate();



    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };


    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const closeAndDirect = (page: string) => {
        setAnchorElNav(null);
        onClick(page)
    };

    const onClickOpen = () => {
        props.onClickOpen();
        setAnchorElNav(null);
    }

    const onClick = (page: string) => {
        props.onClick(page);
    };

    const onClickHome = () => {
        props.onClick('Home')
        navigate('/home')
    }

    return (
        <AppBar position="fixed" style={{background: gray}}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{display: {xs: 'none', md: 'flex'}, ml: '10%', mr: 6}} onClick={onClickHome}>
                        <img src={mainLogoGray} alt="logo" style={{height: 40}}/>
                    </Box>

                    <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon/>
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', md: 'none'}, width: '100%', height: '100%',
                            }}
                        >
                            {props.pages.map((page) => (
                                <PageMenuItem page={page} handleCloseNavMenu={closeAndDirect}/>
                            ))}
                            <MenuItem key="advies" onClick={onClickOpen} sx={{backgroundColor: lightGreen, borderRadius: '4px', ml: '7%', mr: '7%'}}>
                                <Typography textAlign="left" sx={{pr: 2.5}}>Gratis advies</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href=""
                        sx={{
                            display: {xs: 'flex', md: 'none'},
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                        onClick={onClickHome}
                    >
                        <Link sx={{display: 'inline-block', color: lightGreen, textDecoration: 'none'}}>A</Link>NEWPOWER
                    </Typography>
                    <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                        {props.pages.map((page) => (
                            <Page
                                handleCloseNavMenu={handleCloseNavMenu}
                                page={page}
                                currentPage={props.currentPage}
                                onClick={onClick}/>
                        ))}
                    </Box>

                    <Box sx={{flexGrow: 0}}>
                        <OfferteForm onClickOpen={onClickOpen}/>

                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
import React from 'react';
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import {darkGreen, white} from '../../colors/colors';
import TextBox from "../../text/TextBox";
import TwoTwoGrid from "../../component/TwoTwoGrid";
import TitleWhite from "../../text/TitleWhite";
import TextBoxWhite from "../../text/TextBoxWhite";
import logo1 from "../../images/Logo1.png"
import logo2 from "../../images/Logo2.png"
import logo3 from "../../images/Logo3.png"
import logo4 from "../../images/Logo4.png"
import logo5 from "../../images/Logo5.png"
import logo6 from "../../images/Logo6.png"
import logo8 from "../../images/Logo8.png"
import certificate from "../../images/certificate.webp";
import BrandLogo from "../../component/BrandLogo";
import SubTitle from "../../text/SubTitle";
import HeaderHomeMd from "../../component/headers/HeaderHomeMd";
import HeaderHomeXs from "../../component/headers/HeaderHomeXs";
import ArrowBulletList from "../../component/ArrowBulletList";
import Tabs from "../../component/tab/Tabs";


const Home = (props: { onClick: (page: string) => void , onClickOpen: () => void}) => {
    // const [dense, setDense] = React.useState(false);

    // const [secondary, setSecondary] = React.useState(false);

    const onClick = (page: string) => {
        props.onClick(page)
    }

    const onClickOpen = () => {
        props.onClickOpen()
    }


    return (
        <Box m="auto">
            {/*<Box sx={{mt: 20}}></Box>*/}
            <HeaderHomeMd onClickOpen={onClickOpen}/>
            <HeaderHomeXs onClickOpen={onClickOpen}/>
            <Box m="auto" justifyContent="center" sx={{backgroundColor: white, mt: 6, mb: 6}}>
                <SubTitle>
                    Zoekt u een betrouwbare installateur die u verzekert van de laagste prijs?
                </SubTitle>
                <TextBox>
                    Wij bij Anewpower garanderen u een betrouwbare installatie tegen de laagste prijs! Zelf doen wij
                    hier nog een stapje bovenop, wij voorzien iedereen die
                    dat wil van gratis advies over zijn energieverbruik. Voordat wij een offerte maken, geven wij altijd
                    eerst gratis advies
                    met een bijhorende energieanalyse. Uit deze energieanalyse kunt u dan zelf bepalen welke installatie
                    het beste voor u past.
                </TextBox>
            </Box>
            <Tabs onClick={onClick}/>
            <ArrowBulletList/>
            <Box sx={{backgroundColor: darkGreen, pb: 6, pt: 6}}>
                <TitleWhite>
                    Betrouwbare, intelligente en kostenefficiënte energieoplossingen
                </TitleWhite>
                <TextBoxWhite>
                    In een wereld waar de vraag naar energie voortdurend groeit en de roep om duurzame oplossingen
                    steeds luider wordt,
                    speelt Anewpower een cruciale rol. Anewpower is gespecialiseerd in het ontwerpen, installeren en
                    onderhouden van
                    energieopwekkingssystemen die de transitie naar een groenere toekomst mogelijk maken. Met onze
                    technische expertise en
                    toewijding aan duurzaamheid vormen we een onmisbare schakel in de energie-industrie.
                </TextBoxWhite>
            </Box>
            <TwoTwoGrid/>
            <Box sx={{backgroundColor: darkGreen, pb: 6, pt: 6}}>
                <TextBoxWhite>
                    Wij hebben als bedrijf een RECERT-certificaat, wat betekent dat wij een door de Belgische Staat
                    erkende installateur zijn.
                    Bij ons bent u dus zeker van een kwalitatieve installatie volgens de regels van de kunst.

                </TextBoxWhite>
                <Box m="auto" justifyContent="center" sx={{display: {xs: 'none', md: 'flex'}}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="top"
                        justifyContent="center"
                        sx={{minWidth: '350px', width: '25%', borderRadius: '16px', backgroundColor: white}}
                    >
                        <Box m="auto" sx={{minWidth: '300px', width: '95%', mt: "1%", mb: '1%'}}>
                            <img src={certificate} alt={"logo"} style={{width: '100%'}}/>
                        </Box>
                    </Grid>
                </Box>
                <Box m="auto" justifyContent="center" sx={{display: {xs: 'flex', md: 'none'}}}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="top"
                        justifyContent="center"
                        sx={{width: '80%', borderRadius: '16px', backgroundColor: white}}
                    >
                        <Box m="auto" sx={{width: '95%', mt: "1%", mb: '1%'}}>
                            <img src={certificate} alt={"logo"} style={{width: '100%'}}/>
                        </Box>
                    </Grid>
                </Box>
            </Box>
            <Box sx={{mt: 6}}>
                <TextBox>
                    Wij zijn een installateur die niet gebonden is aan een bepaald merk.
                    Wilt u graag een installatie van een bepaald merk, geen probleem, dan zullen wij een installatie
                    voorzien van het merk dat u verkiest.
                    Hieronder volgt een niet-limitatieve opsomming van de merken waarmee we samenwerken:
                </TextBox>
            </Box>
            <Box sx={{display: {xs: 'none', md: 'flex'}}}>
                <Grid
                    container
                    spacing={6}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    sx={{mt: 6, mb: 6}}
                >
                    <BrandLogo src={logo1}/>
                    <BrandLogo src={logo2}/>
                    <BrandLogo src={logo3}/>
                    <BrandLogo src={logo4}/>
                    <BrandLogo src={logo5}/>
                    <BrandLogo src={logo6}/>
                    <BrandLogo src={logo8}/>
                </Grid>
            </Box>
            <Box sx={{display: {xs: 'flex', md: 'none'}}}>
                <Grid
                    container
                    spacing={6}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{mt: 6, mb: 6}}
                >
                    <BrandLogo src={logo1}/>
                    <BrandLogo src={logo2}/>
                    <BrandLogo src={logo3}/>
                    <BrandLogo src={logo4}/>
                    <BrandLogo src={logo5}/>
                    <BrandLogo src={logo6}/>
                    <BrandLogo src={logo8}/>
                </Grid>
            </Box>

        </Box>

    )
        ;
}

export default Home;
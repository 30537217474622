import {Box, Divider} from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {lightGreen, white} from "../colors/colors";
import React from "react";


const Product = (props: { image: string, title: string, info: JSX.Element }) => {
    return (
        <React.Fragment>
            <Box
                p={3}
                sx={{
                    display: {xs: 'none', md: 'flex'},
                    maxWidth: '25%',
                    backgroundColor: white,
                    borderRadius: '16px',
                    mt: 6,
                    mb: 6,
                    ml: '2%',
                    mr: '2%',
                    pb: 4
                }}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                >
                    <img src={props.image} alt="Logo"/>
                    <Typography gutterBottom align="center" variant="h5" component="div" sx={{mt: 4}}>
                        {props.title}
                    </Typography>
                    <Divider variant="middle"
                             sx={{borderBottomWidth: 2, backgroundColor: lightGreen, width: '90%', mb: 2}}/>
                    <Typography variant="body1" align="justify" color="text.secondary" sx={{width: '90%'}}>
                        {props.info}
                    </Typography>
                </Grid>
            </Box>
            <Box
                p={3}
                sx={{
                    display: {xs: 'flex', md: 'none'},
                    maxWidth: '90%',
                    backgroundColor: white,
                    borderRadius: '16px',
                    mt: 6,
                    mb: 6,
                    ml: '5%',
                    mr: '5%',
                    pb: 4
                }}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignContent="center"
                    alignItems="center"
                    justifyContent="center"
                >
                    <img src={props.image} alt="Logo" style={{width: '30%'}}/>
                    <Typography gutterBottom align="center" variant="h5" component="div" sx={{mt: 4}}>
                        {props.title}
                    </Typography>
                    <Divider variant="middle"
                             sx={{borderBottomWidth: 2, backgroundColor: lightGreen, width: '80%', mb: 2}}/>
                    <Typography variant="body2" align="justify" color="text.secondary" sx={{width: '80%'}}>
                        {props.info}
                    </Typography>
                </Grid>
            </Box>
        </React.Fragment>

    );
}

export default Product;
import React from 'react';
import TextBox from "../text/TextBox";
import {
    eerlijkheid,
    eerlijkheidTitle,
    intro1,
    intro2,
    intro3,
    outro,
    transparantie,
    transparantieTitle,
    vertrouwen,
    vertrouwenTitle
} from "../textblocks/OverOnsText";
import {Box} from "@mui/material";
import Grid from "@mui/material/Grid";
import {account, circle, scale} from "../icons/icons";
import ModalCardWithoutIcon from "../component/modals/ModalCardWithoutIcon";
import ModalCardWithoutIconMobile from "../component/modals/ModalCardWithoutIconMobile";
import HeaderOverOnsMd from "../component/headers/HeaderOverOnsMd";
import HeaderOverOnsXs from "../component/headers/HeaderOverOnsXs";

const blocks: { title: string, explanation: string, icon: JSX.Element }[] = [
    {title: eerlijkheidTitle, explanation: eerlijkheid, icon: scale},
    {title: transparantieTitle, explanation: transparantie, icon: circle},
    {title: vertrouwenTitle, explanation: vertrouwen, icon: account}];
const OverOns = () => {
    return (
        <Box>
            <HeaderOverOnsMd blocks={blocks}/>
            <HeaderOverOnsXs blocks={blocks}/>
            <TextBox>
                {intro1}
            </TextBox>
            <TextBox>
                {intro2}
            </TextBox>
            <TextBox>
                {intro3}
            </TextBox>
            <TextBox>
                {outro}
            </TextBox>
            <Box sx={{display: {xs: 'none', md: 'flex'}, mt: 4}}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="top"
                        justifyContent="center"
                    >
                        <ModalCardWithoutIcon
                            title={'Betrouwbaarheid en innovatie'}
                        >
                            Anewpower wordt gekenmerkt door zijn betrouwbaarheid en expertise op het gebied van
                            energieopwekking. Wij werken nauw samen met klanten om een op maat gemaakte oplossing te
                            bieden die voldoet aan de specifieke energiebehoeften van elke locatie. Of het nu gaat om
                            zonne-energie, batterijen laadpalen of andere duurzame bronnen. Anewpower staat bekend om
                            zijn vermogen om innovatieve systemen te ontwikkelen en te implementeren.
                        </ModalCardWithoutIcon>
                        <ModalCardWithoutIcon
                            title={'Duurzaam en milieubewust'}
                        >
                            In de strijd tegen klimaatverandering speelt Anewpower een cruciale rol bij het verminderen
                            van de afhankelijkheid van fossiele brandstoffen. Wij en onze klanten dragen bij aan de
                            vermindering van de uitstoot van broeikasgassen door duurzame energieoplossingen te
                            bevorderen. Door gebruik te maken van hernieuwbare energiebronnen helpen we de ecologische
                            voetafdruk te verkleinen en streven we naar een schonere en gezondere planeet voor
                            toekomstige generaties.
                        </ModalCardWithoutIcon>
                    </Grid>
                    <Grid
                        container
                        spacing={0}
                        direction="row"
                        alignItems="top"
                        justifyContent="center"
                    >
                        <ModalCardWithoutIcon
                            title={'Efficiëntie en kostenbesparing'}
                        >
                            Energie-efficiëntie is een ander belangrijk aspect waarop Anewpower zich richt. Door
                            geavanceerde technologieën en intelligente systemen te gebruiken, streven we naar maximale
                            efficiëntie bij het opwekken en gebruiken van energie. Dit resulteert niet alleen in lagere
                            energiekosten voor onze klanten, maar ook in een vermindering van verspilling en een
                            optimalisatie van het energieverbruik.
                        </ModalCardWithoutIcon>
                        <ModalCardWithoutIcon
                            title={'Service na verkoop'}
                        >
                            Naast installatie biedt Anewpower ook uitgebreide onderhouds- en serviceprogramma's aan. We
                            zorgen ervoor dat de energieopwekkingssystemen optimaal blijven functioneren. Snelle respons
                            op noodsituaties en een grondige technische ondersteuning maken deel uit van onze toewijding
                            om klanten tevreden te stellen en ervoor te zorgen dat uw energievoorziening altijd stabiel
                            is.
                        </ModalCardWithoutIcon>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{display: {xs: 'flex', md: 'none'}, mt: 4}}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="top"
                        justifyContent="center"
                    >
                        <ModalCardWithoutIconMobile
                            title={'Betrouwbaarheid en innovatie'}
                        >
                            Anewpower wordt gekenmerkt door zijn betrouwbaarheid en expertise op het gebied van
                            energieopwekking. Wij werken nauw samen met klanten om op maat gemaakte oplossingen te
                            bieden die voldoen aan de specifieke energiebehoeften van elke locatie. Of het nu gaat om
                            zonne-energie, batterijen laadpalen of andere duurzame bronnen, Anewpower staat bekend om
                            zijn vermogen om innovatieve systemen te ontwikkelen en te implementeren.
                        </ModalCardWithoutIconMobile>
                        <ModalCardWithoutIconMobile
                            title={'Duurzaamheid en milieubewustzijn'}
                        >
                            In de strijd tegen klimaatverandering speelt Anewpower een cruciale rol bij het verminderen
                            van de afhankelijkheid van fossiele brandstoffen. Wij en onze klanten dragen bij aan de
                            vermindering van de uitstoot van broeikasgassen door duurzame energieoplossingen te
                            bevorderen. Door gebruik te maken van hernieuwbare energiebronnen helpen we de ecologische
                            voetafdruk te verkleinen en streven we naar een schonere en gezondere planeet voor
                            toekomstige generaties.
                        </ModalCardWithoutIconMobile>
                        <ModalCardWithoutIconMobile
                            title={'Efficiëntie en kostenbesparing'}
                        >
                            Energie-efficiëntie is een ander belangrijk aspect waarop Anewpower zich richt. Door
                            geavanceerde technologieën en intelligente systemen te gebruiken, streven we naar maximale
                            efficiëntie bij het opwekken en gebruiken van energie. Dit resulteert niet alleen in lagere
                            energiekosten voor onze klanten, maar ook in een vermindering van verspilling en een
                            optimalisatie van het energieverbruik.
                        </ModalCardWithoutIconMobile>
                        <ModalCardWithoutIconMobile
                            title={'Service na verkoop'}
                        >
                            Naast installatie biedt Anewpower ook uitgebreide onderhouds- en serviceprogramma's aan. We
                            zorgen ervoor dat de energieopwekkingssystemen optimaal blijven functioneren. Snelle respons
                            op noodsituaties en een grondige technische ondersteuning maken deel uit van onze toewijding
                            om klanten tevreden te stellen en ervoor te zorgen dat u energievoorziening altijd stabiel
                            is.
                        </ModalCardWithoutIconMobile>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
}

export default OverOns;
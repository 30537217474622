import TextField from "@mui/material/TextField";
import * as React from "react";
import {useState} from "react";
import {Box, Typography} from "@mui/material";
import {lightGreen, white} from "../colors/colors";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";


const Calculator = () => {
    const [vermogen, setVermogen] = useState(0.0);
    const [result, setResult] = useState(0.0);

    const vermogenChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (/^\d+(\.\d{0,2})?$/.test(event.target.value)) {
            setVermogen(parseFloat(event.target.value))
        }

    }

    const onClick = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        console.log(vermogen)
        if (vermogen <= 4) {
            setResult(150 * vermogen);
        } else if (vermogen > 6) {
            setResult(750);
        } else {
            setResult(150 * 4 + (vermogen - 4) * 75);
        }
    }

    return (
        <Box sx={{display: {xs: 'none', md: 'Block'}}}>
            < Typography
                sx={{
                    display: {xs: 'none', md: 'flex', color: white},
                    ml: 1
                }}
                align="justify"
            >
                Hier kan u uw premie in 2023 berkenen. Vul uw vermogen in in kWp.
            </Typography>
            <form onSubmit={onClick}>
                <Grid
                    container>
                    <Grid
                        item
                        xs={6}
                    >
                        <Box sx={{borderRadius: '16px', backgroundColor: 'white', width: '240px'}}>
                            <TextField
                                autoFocus
                                margin="dense"
                                id="vermogen"
                                label="vb. 4.25"
                                type="text"
                                onChange={vermogenChangeHandler}
                                sx={{borderColor: 'white', ml: 1}}
                            />
                        </Box>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sx={{mt: 4}}>
                        <Button sx={{backgroundColor: lightGreen}} type="submit">
                            <Typography align="justify" color="black">
                                Bereken
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </form>
            {
                result !== 0.0 &&
                <Box sx={{mt: 2}}>

                    <Typography
                        sx={{
                            display: {xs: 'none', md: 'flex', color: white},
                            ml: 1,
                            whiteSpace: 2
                        }}
                        align="justify"
                    >
                        Uw berkende premie bedraagt:
                    </Typography>
                    <Typography
                        sx={{
                            display: {xs: 'none', md: 'flex', color: white},
                            ml: 1,
                            whiteSpace: 2
                        }}
                        align="justify"
                    >
                        <b>{result + ' euro'}</b>
                    </Typography>
                </Box>
            }
        </Box>
    );
}
export default Calculator;